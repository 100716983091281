<h1 mat-dialog-title>{{'Password recovery' | translate}}</h1>
<div mat-dialog-content>
    <p>
        {{'Enter your email' | translate}}:
    </p>
    <mat-form-field class="example-full-width">
        <mat-label>{{'Email' | translate}}</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
            placeholder="Ex. pat@example.com">

        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            {{'Please enter a valid email address' | translate}}
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
            {{'Email is required' | translate}}
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" [disabled]="!emailFormControl.valid">
        {{'Send validation key' | translate}}</button>    
</div>