export class LocalizeHelper {
    static localize(value: any, lang: string): string {
        if (!value) {
            return '';
        }
        if (value.hasOwnProperty(lang)) {
            return value[lang];
        }
        return value;
    }
}

