import { Map, Record } from 'immutable';
import { Common } from './models/common.model';

export interface CommonState extends Map<string, any> {
    appLoaded: boolean;

    getPaymentListLoading: boolean;
    getPaymentListLoaded: boolean;
    getPaymentListFail: boolean;
    getPaymentListData: any;

    getCategoryListLoading: boolean;
    getCategoryListLoaded: boolean;
    getCategoryListFail: boolean;
    getCategoryListData: any;

    getCategoryItemLoading: boolean;
    getCategoryItemLoaded: boolean;
    getCategoryItemFail: boolean;
    getCategoryItemData: any;

    uploadFileLoading: boolean;
    uploadFileLoaded: boolean;
    uploadFileFail: boolean;
    uploadFileData: any;
    uploadFileErrMess: string;

    updatePositionLoading: boolean;
    updatePositionLoaded: boolean;
    updatePositionFail: boolean;

    shopPagesLoading: boolean;
    shopPagesLoaded: boolean;
    shopPagesFail: boolean;
    shopPagesData: any;

    getProductsListLoading: boolean;
    getProductsListLoaded: boolean;
    getProductsListFail: boolean;
    getProductsListData: any;

    getPrestashopProductsListInLoading: boolean;
    getPrestashopProductsListInFailData: any;
    getPrestashopProductsListInData: any;

    getProductItemLoading: boolean;
    getProductItemLoaded: boolean;
    getProductItemFail: boolean;
    getProductItemData: any;

    addNotificationLoading: boolean;
    addNotificationLoaded: boolean;
    addNotificationFail: boolean;
    addNotificationData: any;

    getCustomersListLoading: boolean;
    getCustomersListLoaded: boolean;
    getCustomersListFail: boolean;
    getCustomersListData: any;

    getOrderStatusSettingsLoading: boolean;
    getOrderStatusSettingsLoaded: boolean;
    getOrderStatusSettingsFail: boolean;
    getOrderStatusSettingsData: any;

    setOrderStatusSettingsLoading: boolean;
    setOrderStatusSettingsLoaded: boolean;
    setOrderStatusSettingsFail: boolean;
    setOrderStatusSettingsData: any;

    getPrestashopProductsListData: Common.IPrestashopProductItem[];
    getPrestashopProductsListFailData: any;
    getPrestashopProductsListLoading: boolean;

    videoUploadData: any;
    videoUploadFailData: any;
    videoUploadLoading: boolean;

    deleteProductGroupData: any;
    deleteProductGroupFailData: any;
    deleteProductGroupLoading: any;

    createProductGroupData: any;
    createProductGroupFailData: any;
    createProductGroupLoading: any;

    getProductGroupItemData: any;
    getProductGroupItemFailData: any;
    getProductGroupItemLoading: any;

    getProductGroupsData: any;
    getProductGroupsFailData: any;
    getProductGroupsLoading: any;

    updateProductGroupItemData: any;
    updateProductGroupItemFailData: any;
    updateProductGroupItemLoading: any;
}

export const CommonRecord = Record({
    appLoaded: false,

    getPaymentListLoading: false,
    getPaymentListLoaded: false,
    getPaymentListFail: false,
    getPaymentListData: {},

    getCategoryListLoading: false,
    getCategoryListLoaded: false,
    getCategoryListFail: false,
    getCategoryListData: null,

    getCategoryItemLoading: false,
    getCategoryItemLoaded: false,
    getCategoryItemFail: false,
    getCategoryItemData: null,

    uploadFileLoading: false,
    uploadFileLoaded: false,
    uploadFileFail: false,
    uploadFileData: null,
    uploadFileErrMess: null,

    updatePositionLoading: false,
    updatePositionLoaded: false,
    updatePositionFail: false,

    shopPagesLoading: false,
    shopPagesLoaded: false,
    shopPagesFail: false,
    shopPagesData: null,

    getProductsListLoading: false,
    getProductsListLoaded: false,
    getProductsListFail: false,
    getProductsListData: null,

    getPrestashopProductsListInLoading: null,
    getPrestashopProductsListInFailData: null,
    getPrestashopProductsListInData: null,

    getProductItemLoading: false,
    getProductItemLoaded: false,
    getProductItemFail: false,
    getProductItemData: null,

    addNotificationLoading: false,
    addNotificationLoaded: false,
    addNotificationFail: false,
    addNotificationData: null,

    getCustomersListLoading: false,
    getCustomersListLoaded: false,
    getCustomersListFail: false,
    getCustomersListData: null,

    getOrderStatusSettingsLoading: false,
    getOrderStatusSettingsLoaded: false,
    getOrderStatusSettingsFail: false,
    getOrderStatusSettingsData: null,

    setOrderStatusSettingsLoading: false,
    setOrderStatusSettingsLoaded: false,
    setOrderStatusSettingsFail: false,
    setOrderStatusSettingsData: null,

    getPrestashopProductsListData: null,
    getPrestashopProductsListFailData: null,
    getPrestashopProductsListLoading: false,

    videoUploadData: null,
    videoUploadFailData: null,
    videoUploadLoading: false,

    deleteProductGroupData: null,
    deleteProductGroupFailData: null,
    deleteProductGroupLoading: null,

    createProductGroupData: null,
    createProductGroupFailData: null,
    createProductGroupLoading: null,

    getProductGroupItemData: null,
    getProductGroupItemFailData: null,
    getProductGroupItemLoading: null,

    getProductGroupsData: null,
    getProductGroupsFailData: null,
    getProductGroupsLoading: null,

    updateProductGroupItemData: null,
    updateProductGroupItemFailData: null,
    updateProductGroupItemLoading: null,
});
