export class ProductModel {
    public id: number;
    public title: any;
    public titlelang: string;

    constructor(res: any) {
        this.id = res.id;
        this.title = res.title;
        this.titlelang = res.titlelang;
    }
}

