export class BrandModel {
    public active: number;
    public associations: any;
    public date_add: string;
    public date_upd: string;
    public description: any;
    public id_manufacturer: number;
    public img: string;
    public link_rewrite: string;
    public meta_description: any;
    public meta_keywords: any;
    public meta_title: any;
    public name: string;
    public short_description: any;

    constructor(res: any) {
        this.active = res.active ? +res.active : 0;
        this.associations = res.associations || null;
        this.date_add = res.date_add ? res.date_add : '';
        this.date_upd = res.date_upd ? res.date_upd : '';
        this.description = res.description ? res.description : {};
        this.id_manufacturer = res.id_manufacturer ? res.id_manufacturer : '';
        this.img = res.img ? res.img : '';
        this.link_rewrite = res.link_rewrite ? res.link_rewrite : '';
        this.meta_description = res.meta_description ? res.meta_description : {};
        this.meta_keywords = res.meta_keywords ? res.meta_keywords : {};
        this.meta_title = res.meta_title ? res.meta_title : {};
        this.name = res.name ? res.name : '';
        this.short_description = res.short_description ? res.short_description : {};
    }
}

