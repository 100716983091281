<h1 mat-dialog-title>
    <ng-container *ngIf="!data">
        {{'Create banner' | translate}}
    </ng-container>
    <ng-container *ngIf="data">
        {{'Edit banner' | translate}}
    </ng-container>
</h1>
<div mat-dialog-content>
    <form [formGroup]="bannerForm">
        <div class="flex">
            <mat-form-field class="flex-auto">
                <!-- <mat-label>{{'idShopName' | translate}}</mat-label> -->
                <input type="text" matInput placeholder="{{'idShopName' | translate}}" [formControl]="shopName">
                <mat-error *ngIf="shopName.hasError('required')">
                    <strong>{{'Required' | translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="flex-auto">
                <mat-label>{{'Banner Name' | translate}}</mat-label>
                <input type="text" matInput placeholder="{{'Banner Name' | translate}}" [formControl]="bannerName">
                <mat-icon class="icon-size-5" matPrefix>notes</mat-icon>
                <mat-error *ngIf="bannerContent.hasError('required')">
                    <strong>{{'Required' | translate}}</strong>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="flex">
            <mat-form-field class="flex-auto">
                <mat-label>{{'Type' | translate}}</mat-label>
                <mat-select [formControl]="bannerType">
                    <mat-option [value]="BANNER_TYPE.image100">
                        {{'Image 100' | translate}}
                    </mat-option>
                    <mat-option [value]="BANNER_TYPE.image50">
                        {{'Image 50' | translate}}
                    </mat-option>
                    <mat-option [value]="BANNER_TYPE.pages" [disabled]="pagesList.length === 0">
                        {{'Pages' | translate}}
                        <span *ngIf="pagesList.length === 0">
                            ({{'empty pages list' | translate}})
                        </span>
                    </mat-option>
                    <mat-option [value]="BANNER_TYPE.slider" [disabled]="availableCategories.length === 0">
                        {{'Slider category' | translate}}
                        <span *ngIf="availableCategories.length === 0">
                            ({{'empty categories list' | translate}})
                        </span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="bannerType.hasError('required')">
                    <strong>{{'Required' | translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex">
            <mat-form-field class="flex-auto">
                <mat-label>{{'Height' | translate}}</mat-label>
                <input type="number" matInput [min]="0" placeholder="{{'Height' | translate}}"
                    [formControl]="bannerHeight">
                <mat-icon class="icon-size-5" matPrefix>photo_size_select_large</mat-icon>
                <span matSuffix>px</span>
                <mat-error *ngIf="bannerHeight.hasError('required')">
                    <strong>{{'Required' | translate}}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <ng-container *ngIf="bannerType.value === BANNER_TYPE.pages">
            <div class="flex">
                <mat-form-field class="flex-auto">
                    <mat-label>{{'Content page' | translate}}</mat-label>
                    <mat-select [formControl]="bannerContent">
                        <mat-option *ngFor="let page of pagesList" [value]="page.id">
                            ID: {{page.id}} - {{page.title | multiLang}}
                        </mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matPrefix>note</mat-icon>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container *ngIf="bannerType.value === BANNER_TYPE.slider">
            <div class="flex">
                <mat-form-field class="flex-auto">
                    <mat-label>{{'Category Id' | translate}}</mat-label>
                    <mat-select [formControl]="category">
                        <mat-option *ngFor="let category of availableCategories" [value]="category">
                            <ng-container *ngIf="category && category.name">
                                ID: {{category.id}} - {{category.name | multiLang}}
                            </ng-container>
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="category.hasError('required')">
                        <strong>{{'Required' | translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="create()" [disabled]="bannerForm.invalid">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Create' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Update' | translate}}
        </ng-container>
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Close' | translate}}
    </button>
</div>