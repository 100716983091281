export class CategoryModel {
    public nbPoductsRecursive: number;
    public name: any;
    public description: any;
    public id: number;
    public idParent: number;
    public isRootCategory: number;
    public levelDepth: number;
    public associations: any;
    public titlelang: string;

    constructor(res: any) {
        this.nbPoductsRecursive = res.nb_products_recursive;
        this.name = res.name;
        this.description = res.description;
        this.id = res.id;
        this.idParent = res.idParent;
        this.isRootCategory = res.is_root_category;
        this.levelDepth = res.level_depth;
        this.associations = res.associations;
        this.titlelang = res.titlelang;
    }
}


