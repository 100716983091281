import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subscription, tap, throttleTime } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { USER_ROLES } from '../shared/helpers/constants.enum';
import { ADMIN_ROOT_NAVIGATION, USER_NAVIGATION, USER_NO_SHOP_NAVIGATION } from './navigation.data';
import { TranslateService } from '@ngx-translate/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private currentUserRole = USER_ROLES.user;
    private currentShopName = '';

    constructor(
        private translate: TranslateService
    ) {

    }

    public changeUserRole(role: USER_ROLES): void {
        this.currentUserRole = role;
        this._navigation.next(this.getNavObjectByRole());
    }

    public changeShopname(name: string): void {
        this.currentShopName = name;
        this._navigation.next(this.getNavObjectByRole());
    }

    private getNavObjectByRole(): Navigation {
        const nav: Navigation = {
            compact: [],
            default: [],
            futuristic: [],
            horizontal: []
        }

        if (!this.currentUserRole) {
            return nav;
        }

        if (this.currentUserRole === USER_ROLES.user) {
            if (this.currentShopName) {

                if (environment.IS_CAN_VIDEO_UPLOAD) {
                    const isAvailable = USER_NAVIGATION.find(el => el.id === 'dashboards.video_upload');
                    if (!isAvailable) {
                        USER_NAVIGATION.push(
                            {
                                id: 'dashboards.video_upload',
                                title: 'Upload video to product',
                                type: 'basic',
                                icon: 'insert_drive_file',
                                link: '/video_upload'
                            }
                        )
                    }
                }

                nav.compact = USER_NAVIGATION;
                nav.default = USER_NAVIGATION;
                nav.futuristic = USER_NAVIGATION;
                nav.horizontal = USER_NAVIGATION;
            } else {
                nav.compact = USER_NO_SHOP_NAVIGATION;
                nav.default = USER_NO_SHOP_NAVIGATION;
                nav.futuristic = USER_NO_SHOP_NAVIGATION;
                nav.horizontal = USER_NO_SHOP_NAVIGATION;
            }
        }
        if (this.currentUserRole === USER_ROLES.admin || this.currentUserRole === USER_ROLES.root) {
            nav.compact = ADMIN_ROOT_NAVIGATION;
            nav.default = ADMIN_ROOT_NAVIGATION;
            nav.futuristic = ADMIN_ROOT_NAVIGATION;
            nav.horizontal = ADMIN_ROOT_NAVIGATION;
        }

        return nav;
    }

    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    get(): Observable<Navigation> {
        return of(this.getNavObjectByRole());
    }
}
