import { Action } from '@ngrx/store';
import { Payload } from '../payload';
import { type } from '../shared/utility/utilityHelper';

export const ActionTypes = {
    APP_LOADING: type('APP_LOADING'),

    GET_PAYMENT_LIST: type('GET_PAYMENT_LIST'),
    GET_PAYMENT_LIST_SUCCESS: type('GET_PAYMENT_LIST_SUCCESS'),
    GET_PAYMENT_LIST_FAIL: type('GET_PAYMENT_LIST_FAIL'),

    GET_CATEGORY_LIST: type('GET_CATEGORY_LIST'),
    GET_CATEGORY_LIST_SUCCESS: type('GET_CATEGORY_LIST_SUCCESS'),
    GET_CATEGORY_LIST_FAIL: type('GET_CATEGORY_LIST_FAIL'),

    GET_CATEGORY_ITEM: type('GET_CATEGORY_ITEM'),
    GET_CATEGORY_ITEM_SUCCESS: type('GET_CATEGORY_ITEM_SUCCESS'),
    GET_CATEGORY_ITEM_FAIL: type('GET_CATEGORY_ITEM_FAIL'),
    CLEAR_GET_CATEGORY_ITEM_STATE: type('CLEAR_GET_CATEGORY_ITEM_STATE'),

    UPLOAD_FILE: type('UPLOAD_FILE'),
    UPLOAD_FILE_SUCCESS: type('UPLOAD_FILE_SUCCESS'),
    UPLOAD_FILE_FAIL: type('UPLOAD_FILE_FAIL'),
    CLEAR_UPLOAD_FILE_STATE: type('CLEAR_UPLOAD_FILE_STATE'),

    UPDATE_POSITION: type('UPDATE_POSITION'),
    UPDATE_POSITION_SUCCESS: type('UPDATE_POSITION_SUCCESS'),
    UPDATE_POSITION_FAIL: type('UPDATE_POSITION_FAIL'),
    CLEAR_UPDATE_POSITION_STATE: type('CLEAR_UPDATE_POSITION_STATE'),

    GET_SHOP_PAGES: type('GET_SHOP_PAGES'),
    GET_SHOP_PAGES_SUCCESS: type('GET_SHOP_PAGES_SUCCESS'),
    GET_SHOP_PAGES_FAIL: type('GET_SHOP_PAGES_FAIL'),

    GET_PRODUCTS_LIST: type('GET_PRODUCTS_LIST'),
    GET_PRODUCTS_LIST_SUCCESS: type('GET_PRODUCTS_LIST_SUCCESS'),
    GET_PRODUCTS_LIST_FAIL: type('GET_PRODUCTS_LIST_FAIL'),

    GET_PRODUCT_ITEM: type('GET_PRODUCT_ITEM'),
    GET_PRODUCT_ITEM_SUCCESS: type('GET_PRODUCT_ITEM_SUCCESS'),
    GET_PRODUCT_ITEM_FAIL: type('GET_PRODUCT_ITEM_FAIL'),
    CLEAR_GET_PRODUCT_ITEM_STATE: type('CLEAR_GET_PRODUCT_ITEM_STATE'),

    ADD_NOTIFICATION: type('ADD_NOTIFICATION'),
    ADD_NOTIFICATION_SUCCESS: type('ADD_NOTIFICATION_SUCCESS'),
    ADD_NOTIFICATION_FAIL: type('ADD_NOTIFICATION_FAIL'),
    CLEAR_ADD_NOTIFICATION_STATE: type('CLEAR_ADD_NOTIFICATION_STATE'),

    GET_CUSTOMERS_LIST: type('GET_CUSTOMERS_LIST'),
    GET_CUSTOMERS_LIST_SUCCESS: type('GET_CUSTOMERS_LIST_SUCCESS'),
    GET_CUSTOMERS_LIST_FAIL: type('GET_CUSTOMERS_LIST_FAIL'),
    CLEAR_GET_CUSTOMERS_LIST_STATE: type('CLEAR_GET_CUSTOMERS_LIST_STATE'),

    GET_ORDER_STATUS_SETTINGS: type('GET_ORDER_STATUS_SETTINGS'),
    GET_ORDER_STATUS_SETTINGS_SUCCESS: type('GET_ORDER_STATUS_SETTINGS_SUCCESS'),
    GET_ORDER_STATUS_SETTINGS_FAIL: type('GET_ORDER_STATUS_SETTINGS_FAIL'),
    CLEAR_GET_ORDER_STATUS_SETTINGS_STATE: type('CLEAR_GET_ORDER_STATUS_SETTINGS_STATE'),

    SET_ORDER_STATUS_SETTINGS: type('SET_ORDER_STATUS_SETTINGS'),
    SET_ORDER_STATUS_SETTINGS_SUCCESS: type('SET_ORDER_STATUS_SETTINGS_SUCCESS'),
    SET_ORDER_STATUS_SETTINGS_FAIL: type('SET_ORDER_STATUS_SETTINGS_FAIL'),
    CLEAR_SET_ORDER_STATUS_SETTINGS_STATE: type('CLEAR_SET_ORDER_STATUS_SETTINGS_STATE'),

    GET_PRESTASHOP_PRODUCTS_LIST: type('GET_PRESTASHOP_PRODUCTS_LIST'),
    GET_PRESTASHOP_PRODUCTS_LIST_FAIL: type('GET_PRESTASHOP_PRODUCTS_LIST fail'),
    GET_PRESTASHOP_PRODUCTS_LIST_SUCCESS: type('GET_PRESTASHOP_PRODUCTS_LIST success'),

    GET_PRESTASHOP_PRODUCTS_LIST_IN: type('GET_PRESTASHOP_PRODUCTS_LIST in'),
    GET_PRESTASHOP_PRODUCTS_LIST_IN_FAIL: type('GET_PRESTASHOP_PRODUCTS_LIST fail in'),
    GET_PRESTASHOP_PRODUCTS_LIST_IN_SUCCESS: type('GET_PRESTASHOP_PRODUCTS_LIST success in'),

    VIDEO_UPLOAD: type('VIDEO_UPLOAD'),
    VIDEO_UPLOAD_FAIL: type('VIDEO_UPLOAD fail'),
    VIDEO_UPLOAD_SUCCESS: type('VIDEO_UPLOAD success'),
    CLEAR_VIDEO_UPLOAD_STATE: type('VIDEO_UPLOAD clear state'),

    DELETE_PRODUCT_GROUP: type('DELETE_PRODUCT_GROUP'),
    DELETE_PRODUCT_GROUP_SUCCESS: type('DELETE_PRODUCT_GROUP success'),
    DELETE_PRODUCT_GROUP_FAIL: type('DELETE_PRODUCT_GROUP fail'),
    CLEAR_DELETE_PRODUCT_GROUP_STATE: type('DELETE_PRODUCT_GROUP clear state'),

    CREATE_PRODUCT_GROUP: type('CREATE_PRODUCT_GROUP'),
    CREATE_PRODUCT_GROUP_SUCCESS: type('CREATE_PRODUCT_GROUP success'),
    CREATE_PRODUCT_GROUP_FAIL: type('CREATE_PRODUCT_GROUP fail'),
    CLEAR_CREATE_PRODUCT_GROUP_STATE: type('CREATE_PRODUCT_GROUP clear state'),

    GET_PRODUCT_GROUP_ITEM: type('GET_PRODUCT_GROUP_ITEM'),
    GET_PRODUCT_GROUP_ITEM_SUCCESS: type('GET_PRODUCT_GROUP_ITEM suceess'),
    GET_PRODUCT_GROUP_ITEM_FAIL: type('GET_PRODUCT_GROUP_ITEM fail'),
    CLEAR_GET_PRODUCT_GROUP_ITEM_STATE: type('GET_PRODUCT_GROUP_ITEM clear state'),

    GET_PRODUCT_GROUPS: type('GET_PRODUCT_GROUPS'),
    GET_PRODUCT_GROUPS_SUCCESS: type('GET_PRODUCT_GROUPS success'),
    GET_PRODUCT_GROUPS_FAIL: type('GET_PRODUCT_GROUPS fail'),

    UPDATE_PRODUCT_GROUP_ITEM: type('UPDATE_PRODUCT_GROUP_ITEM'),
    UPDATE_PRODUCT_GROUP_ITEM_SUCCESS: type('UPDATE_PRODUCT_GROUP_ITEM success'),
    UPDATE_PRODUCT_GROUP_ITEM_FAIL: type('UPDATE_PRODUCT_GROUP_ITEM fail'),
    CLEAR_UPDATE_PRODUCT_GROUP_ITEM_STATE: type('UPDATE_PRODUCT_GROUP_ITEM clear state'),
};
// 
export class UpdateProductGroupItemAction extends Payload implements Action {
    type = ActionTypes.UPDATE_PRODUCT_GROUP_ITEM;
}
export class UpdateProductGroupItemFailAction extends Payload implements Action {
    type = ActionTypes.UPDATE_PRODUCT_GROUP_ITEM_FAIL;
}
export class UpdateProductGroupItemSuccessAction extends Payload implements Action {
    type = ActionTypes.UPDATE_PRODUCT_GROUP_ITEM_SUCCESS;
}
export class ClearUpdateProductGroupItemStateAction extends Payload implements Action {
    type = ActionTypes.CLEAR_UPDATE_PRODUCT_GROUP_ITEM_STATE;
}
// 
export class GetProductGroupsAction implements Action {
    type = ActionTypes.GET_PRODUCT_GROUPS;
    constructor(public payload: any) { }
}
export class GetProductGroupsFailAction implements Action {
    type = ActionTypes.GET_PRODUCT_GROUPS_FAIL;
    constructor(public payload: any) { }
}
export class GetProductGroupsSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_GROUPS_SUCCESS;
    constructor(public payload: any) { }
}
// 
export class GetProductGroupItemAction extends Payload implements Action {
    type = ActionTypes.GET_PRODUCT_GROUP_ITEM;
}
export class GetProductGroupItemFailAction extends Payload implements Action {
    type = ActionTypes.GET_PRODUCT_GROUP_ITEM_FAIL;
}
export class GetProductGroupItemSuccessAction extends Payload implements Action {
    type = ActionTypes.GET_PRODUCT_GROUP_ITEM_SUCCESS;
}
export class ClearGetProductGroupItemStateAction extends Payload implements Action {
    type = ActionTypes.CLEAR_GET_PRODUCT_GROUP_ITEM_STATE;
}
// 
export class DeleteProductGroupAction extends Payload implements Action {
    type = ActionTypes.DELETE_PRODUCT_GROUP
}
export class DeleteProductGroupFailAction extends Payload implements Action {
    type = ActionTypes.DELETE_PRODUCT_GROUP_FAIL
}
export class DeleteProductGroupSuccessAction extends Payload implements Action {
    type = ActionTypes.DELETE_PRODUCT_GROUP_SUCCESS
}
export class ClearDeleteProductGroupStateAction extends Payload implements Action {
    type = ActionTypes.CLEAR_DELETE_PRODUCT_GROUP_STATE
}
// 
export class CreateProductGroupAction extends Payload implements Action {
    type = ActionTypes.CREATE_PRODUCT_GROUP;
}
export class CreateProductGroupSuccessAction extends Payload implements Action {
    type = ActionTypes.CREATE_PRODUCT_GROUP_SUCCESS;
}
export class CreateProductGroupFailAction extends Payload implements Action {
    type = ActionTypes.CREATE_PRODUCT_GROUP_FAIL;
}
export class ClearCreateProductGroupStateAction extends Payload implements Action {
    type = ActionTypes.CLEAR_CREATE_PRODUCT_GROUP_STATE;
}


export class AppLoadingAction implements Action {
    type = ActionTypes.APP_LOADING;
    constructor(public payload: any) { }
}

export class GetPaymentListAction implements Action {
    type = ActionTypes.GET_PAYMENT_LIST;
    constructor(public payload: any) { }
}
export class GetPaymentListSuccessAction implements Action {
    type = ActionTypes.GET_PAYMENT_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetPaymentListFailAction implements Action {
    type = ActionTypes.GET_PAYMENT_LIST_FAIL;
    constructor(public payload: any) { }
}

export class GetCategoryListAction implements Action {
    type = ActionTypes.GET_CATEGORY_LIST;
    constructor(public payload: any) { }
}
export class GetCategoryListFailAction implements Action {
    type = ActionTypes.GET_CATEGORY_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetCategoryListSuccessAction implements Action {
    type = ActionTypes.GET_CATEGORY_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class GetCategoryItemAction implements Action {
    type = ActionTypes.GET_CATEGORY_ITEM;
    constructor(public payload: any) { }
}
export class GetCategoryItemFailAction implements Action {
    type = ActionTypes.GET_CATEGORY_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class GetCategoryItemSuccessAction implements Action {
    type = ActionTypes.GET_CATEGORY_ITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetCategoryItemStateAction implements Action {
    type = ActionTypes.CLEAR_GET_CATEGORY_ITEM_STATE;
    constructor(public payload: any) { }
}

export class UploadFileAction implements Action {
    type = ActionTypes.UPLOAD_FILE;
    constructor(public payload: any) { }
}
export class UploadFileFailAction implements Action {
    type = ActionTypes.UPLOAD_FILE_FAIL;
    constructor(public payload: any) { }
}
export class UploadFileSuccessAction implements Action {
    type = ActionTypes.UPLOAD_FILE_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUploadFileStateAction implements Action {
    type = ActionTypes.CLEAR_UPLOAD_FILE_STATE;
    constructor(public payload: any) { }
}

export class UpdatePositionAction implements Action {
    type = ActionTypes.UPDATE_POSITION;
    constructor(public payload: any) { }
}
export class UpdatePositionSuccessAction implements Action {
    type = ActionTypes.UPDATE_POSITION_SUCCESS;
    constructor(public payload: any) { }
}
export class UpdatePositionFailAction implements Action {
    type = ActionTypes.UPDATE_POSITION_FAIL;
    constructor(public payload: any) { }
}
export class ClearUpdatePositionStateAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_POSITION_STATE;
    constructor(public payload: any) { }
}

export class GetShopPagesAction implements Action {
    type = ActionTypes.GET_SHOP_PAGES;
    constructor(public payload: any) { }
}
export class GetShopPagesSuccessAction implements Action {
    type = ActionTypes.GET_SHOP_PAGES_SUCCESS;
    constructor(public payload: any) { }
}
export class GetShopPagesFailAction implements Action {
    type = ActionTypes.GET_SHOP_PAGES_FAIL;
    constructor(public payload: any) { }
}

export class GetProductsListAction implements Action {
    type = ActionTypes.GET_PRODUCTS_LIST;
    constructor(public payload: any) { }
}
export class GetProductsListFailAction implements Action {
    type = ActionTypes.GET_PRODUCTS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetProductsListSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCTS_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class GetProductItemAction implements Action {
    type = ActionTypes.GET_PRODUCT_ITEM;
    constructor(public payload: any) { }
}
export class GetProductItemSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCT_ITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class GetProductItemFailAction implements Action {
    type = ActionTypes.GET_PRODUCT_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class ClearGetProductItemStateAction implements Action {
    type = ActionTypes.CLEAR_GET_PRODUCT_ITEM_STATE;
    constructor(public payload: any) { }
}

export class AddNotificationAction implements Action {
    type = ActionTypes.ADD_NOTIFICATION;
    constructor(public payload: any) { }
}
export class AddNotificationFailAction implements Action {
    type = ActionTypes.ADD_NOTIFICATION_FAIL;
    constructor(public payload: any) { }
}
export class AddNotificationSuccessAction implements Action {
    type = ActionTypes.ADD_NOTIFICATION_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearAddNotificationStateAction implements Action {
    type = ActionTypes.CLEAR_ADD_NOTIFICATION_STATE;
    constructor(public payload: any) { }
}

export class GetCustomersListAction implements Action {
    type = ActionTypes.GET_CUSTOMERS_LIST;
    constructor(public payload: any) { }
}
export class GetCustomersListFailAction implements Action {
    type = ActionTypes.GET_CUSTOMERS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetCustomersListSuccessAction implements Action {
    type = ActionTypes.GET_CUSTOMERS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetCustomersListStateAction implements Action {
    type = ActionTypes.CLEAR_GET_CUSTOMERS_LIST_STATE;
    constructor(public payload: any) { }
}

export class GetOrderStatusSettingsAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_SETTINGS;
    constructor(public payload: any) { }
}
export class GetOrderStatusSettingsFailAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_SETTINGS_FAIL;
    constructor(public payload: any) { }
}
export class GetOrderStatusSettingsSuccessAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_SETTINGS_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetOrderStatusSettingsStateAction implements Action {
    type = ActionTypes.CLEAR_GET_ORDER_STATUS_SETTINGS_STATE;
    constructor(public payload: any) { }
}

export class SetOrderStatusSettingsAction implements Action {
    type = ActionTypes.SET_ORDER_STATUS_SETTINGS;
    constructor(public payload: any) { }
}
export class SetOrderStatusSettingsFailAction implements Action {
    type = ActionTypes.SET_ORDER_STATUS_SETTINGS_FAIL;
    constructor(public payload: any) { }
}
export class SetOrderStatusSettingsSuccessAction implements Action {
    type = ActionTypes.SET_ORDER_STATUS_SETTINGS_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearSetOrderStatusSettingsStateAction implements Action {
    type = ActionTypes.CLEAR_SET_ORDER_STATUS_SETTINGS_STATE;
    constructor(public payload: any) { }
}
// 
export class GetPrestashopProductsListAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST;
    constructor(public payload: any) { }
}
export class GetPrestashopProductsListFailAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetPrestashopProductsListSuccessAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetPrestashopProductsListInAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_IN;
    constructor(public payload: any) { }
}
export class GetPrestashopProductsListInFailAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_IN_FAIL;
    constructor(public payload: any) { }
}
export class GetPrestashopProductsListInSuccessAction implements Action {
    type = ActionTypes.GET_PRESTASHOP_PRODUCTS_LIST_IN_SUCCESS;
    constructor(public payload: any) { }
}

export class VideoUploadAction implements Action {
    type = ActionTypes.VIDEO_UPLOAD;
    constructor(public payload: any) { }
}
export class VideoUploadFailAction implements Action {
    type = ActionTypes.VIDEO_UPLOAD_FAIL;
    constructor(public payload: any) { }
}
export class VideoUploadSuccessAction implements Action {
    type = ActionTypes.VIDEO_UPLOAD_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearVideoUploadStateAction implements Action {
    type = ActionTypes.CLEAR_VIDEO_UPLOAD_STATE;
    constructor(public payload: any) { }
}

export type Actions =
    | AppLoadingAction
    | GetPaymentListAction
    | GetPaymentListSuccessAction
    | GetPaymentListFailAction
    | GetCategoryListAction
    | GetCategoryListFailAction
    | GetCategoryListSuccessAction
    | GetCategoryItemAction
    | GetCategoryItemFailAction
    | GetCategoryItemSuccessAction
    | ClearGetCategoryItemStateAction
    | UploadFileAction
    | UploadFileFailAction
    | UploadFileSuccessAction
    | ClearUploadFileStateAction
    | UpdatePositionAction
    | UpdatePositionFailAction
    | UpdatePositionSuccessAction
    | ClearUpdatePositionStateAction
    | GetShopPagesAction
    | GetShopPagesFailAction
    | GetShopPagesSuccessAction
    | GetProductsListAction
    | GetProductsListFailAction
    | GetProductsListSuccessAction
    | GetProductItemAction
    | GetProductItemFailAction
    | GetProductItemSuccessAction
    | ClearGetProductItemStateAction
    | AddNotificationAction
    | AddNotificationFailAction
    | AddNotificationSuccessAction
    | ClearAddNotificationStateAction
    | GetCustomersListAction
    | GetCustomersListFailAction
    | GetCustomersListSuccessAction
    | ClearGetCustomersListStateAction
    | GetOrderStatusSettingsAction
    | GetOrderStatusSettingsFailAction
    | GetOrderStatusSettingsSuccessAction
    | ClearGetOrderStatusSettingsStateAction
    | SetOrderStatusSettingsAction
    | SetOrderStatusSettingsFailAction
    | SetOrderStatusSettingsSuccessAction
    | ClearSetOrderStatusSettingsStateAction
    | GetPrestashopProductsListAction
    | GetPrestashopProductsListFailAction
    | GetPrestashopProductsListSuccessAction
    | VideoUploadAction
    | VideoUploadFailAction
    | VideoUploadSuccessAction
    | ClearVideoUploadStateAction
    | DeleteProductGroupAction
    | DeleteProductGroupFailAction
    | DeleteProductGroupSuccessAction
    | ClearDeleteProductGroupStateAction
    | CreateProductGroupAction
    | CreateProductGroupFailAction
    | CreateProductGroupSuccessAction
    | ClearCreateProductGroupStateAction
    | GetProductGroupItemAction
    | GetProductGroupItemFailAction
    | GetProductGroupItemSuccessAction
    | ClearGetProductGroupItemStateAction
    | GetProductGroupsAction
    | GetProductGroupsFailAction
    | GetProductGroupsSuccessAction
    | UpdateProductGroupItemAction
    | UpdateProductGroupItemFailAction
    | UpdateProductGroupItemSuccessAction
    | ClearUpdateProductGroupItemStateAction
    | GetPrestashopProductsListInAction
    | GetPrestashopProductsListInFailAction
    | GetPrestashopProductsListInSuccessAction
