import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { ProductModel } from 'app/core/common/models/product.model';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './product-select.dialog.html',
    styleUrls: ['./product-select.dialog.scss']
})
export class ProductSelectDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    myControl = new FormControl();
    idShopname = '';
    productsList: ProductModel[] = [];

    constructor(
        private dialogRef: MatDialogRef<ProductSelectDialogComponent>,
        public commonSandbox: CommonSandbox,
        private shopSandbox: ShopSandbox,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.idShopname = shopName || '';
            this.getProducts();
        }));
        this.subs.push(this.commonSandbox.getProductsListData$.subscribe(res => {
            this.productsList = res || [];
        }));
        this.myControl.valueChanges.subscribe(res => {
            this.getProducts(res);
        });
    }

    private getProducts(key = ''): void {
        if (!this.idShopname) {
            return;
        }
        // console.log(this.translate.currentLang);
        const params: any = {
            keyword: key,
            lng: this.translate.currentLang,
            limit: '40',
            offset: '0',
            idShopname: this.idShopname
        };

        this.commonSandbox.doGetProductsList(params);
    }

    select(product: ProductModel): void {
        // console.log(product);
        this.dialogRef.close(product);
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}

