import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Api } from '../api';
import { AppSettingService } from '../shared/app.settings';

@Injectable()
export class ShopService extends Api {
    // private apiUrl: string = this.getApiUrl() + this.getApiVersion();

    private url = '/api/dashboard/' + this.getApiVersion();
    private prestaUrl = '/api/prestashop/' + this.getApiVersion();

    constructor(
        private http: HttpClient,
        protected appSettings: AppSettingService
    ) {
        super();
    }

    public addShop(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/shop-upsert', params);
    }

    public getShopSettings(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/settings-shop', params);
    }

    public getMenuItem(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/menu', { params });
    }

    public updateMenuItem(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/menu-update', params);
    }

    public getOrderStatusList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/order-states', { params });
    }

    public getShopLangList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }

        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/languages-list', { params });
    }

    public getBrandsList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/manufacturers-list', { params });
    }

    public getCurrencyList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/currency-list', params);
    }

    public createPaypalHook(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/paypal-createhook', params);
    }

    public createStripeHook(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.prestaUrl + '/stripe-createhook', params);
    }

    public getShopErrorList(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.get(this.getApiUrl() + this.appSettings.clusterId + this.url + '/errorslog', params);
    }

    public updateErrorStatus(params: any): Observable<any> {
        if (!this.appSettings.clusterId) {
            return of({ message: 'invalid cluster id' });
        }
        return this.http.post(this.getApiUrl() + this.appSettings.clusterId + this.url + '/errorstatus-update', params);
    }
}

