import { AccountRecord, AccountState } from './account.state';
import * as actions from './account.action';
import { RegisterUserModel, UserModel } from './models/registerUser.model';
import { LoginUserModel } from './models/loginUser.model';

export const initialState: AccountState = (new AccountRecord() as unknown) as AccountState;

export function reducer(state = initialState, { type, payload }: any): AccountState {
    if (!type) {
        return state;
    }
    switch (type) {
        case actions.ActionTypes.REGISTER_USER: {
            return Object.assign({}, state, {
                registerUserLoading: true,
                registerUserLoaded: false,
                registerUserFail: false,
                registerUserData: null,
                registerUserErrors: null,
            });
        }
        case actions.ActionTypes.REGISTER_USER_SUCCESS: {
            let errMessage = null;
            let registeredUser = null;
            if (payload && payload.isError) {
                errMessage = payload.message || '';
            } else if (payload) {
                registeredUser = new RegisterUserModel(payload);
            }
            // console.log(payload);
            return Object.assign({}, state, {
                registerUserLoading: false,
                registerUserLoaded: true,
                registerUserFail: false,
                registerUserData: registeredUser,
                registerUserErrors: errMessage,
            });
        }
        case actions.ActionTypes.REGISTER_USER_FAIL: {
            return Object.assign({}, state, {
                registerUserLoading: false,
                registerUserLaded: false,
                registerUserFail: true,
                registerUserData: null,
                registerUserErrors: null,
            });
        }
        case actions.ActionTypes.CLEAR_REGISTER_STATE: {
            return Object.assign({}, state, {
                registerUserLoading: false,
                registerUserLaded: false,
                registerUserFail: false,
                registerUserData: null,
                registerUserErrors: null,
            });
        }

        case actions.ActionTypes.LOGIN_USER: {
            return Object.assign({}, state, {
                loginUserLoading: true,
                loginUserLoaded: false,
                loginUserFail: false,
                loginUserData: null,
                loginUserErrors: null,
                currentUserToken: null,
                // currentUserRole: null,
            });
        }
        case actions.ActionTypes.LOGIN_USER_SUCCESS: {
            let errMessage = null;
            let loginedUser = null;
            // console.log(payload);
            if (payload && payload.IsError) {
                errMessage = payload.message || '';
            } else if (payload && payload.data) {
                loginedUser = new LoginUserModel(payload.data);
                // console.log('*---', loginedUser.token);
            }

            // console.log(payload);
            return Object.assign({}, state, {
                loginUserLoading: false,
                loginUserLoaded: true,
                loginUserFail: false,
                loginUserData: loginedUser,
                loginUserErrors: errMessage,
                lastLoginedUserInfo: loginedUser,
                currentUserToken: loginedUser ? loginedUser.token : '',
                currentUserRole: loginedUser ? loginedUser.role : ''
            });
        }
        case actions.ActionTypes.LOGIN_USER_FAIL: {
            return Object.assign({}, state, {
                loginUserLoading: false,
                loginUserLoaded: false,
                loginUserFail: true,
                loginUserData: null,
                loginUserErrors: null,
                currentUserToken: null,
                currentUserRole: null,
            });
        }
        case actions.ActionTypes.CLEAR_LOGIN_STATE: {
            return Object.assign({}, state, {
                loginUserLoading: false,
                loginUserLoaded: false,
                loginUserFail: false,
                loginUserData: null,
                loginUserErrors: null
            });
        }

        case actions.ActionTypes.SEND_PASSWORD_RECOVERY_KEY: {
            return Object.assign({}, state, {
                sendPasswordRecoveryKeyLoading: true,
                sendPasswordRecoveryKeyLoaded: false,
                sendPasswordRecoveryKeyFail: false,
                sendPasswordRecoveryKeyData: null,
                sendPasswordRecoveryKeyErrMessage: null,
            });
        }
        case actions.ActionTypes.SEND_PASSWORD_RECOVERY_KEY_SUCCESS: {
            // console.log(payload);
            const err = !payload.success;
            const errMes = err && payload.message ? payload.message : null;

            return Object.assign({}, state, {
                sendPasswordRecoveryKeyLoading: false,
                sendPasswordRecoveryKeyLoaded: true,
                sendPasswordRecoveryKeyFail: false,
                sendPasswordRecoveryKeyData: !err ? payload : null,
                sendPasswordRecoveryKeyErrMessage: errMes,
            });
        }
        case actions.ActionTypes.SEND_PASSWORD_RECOVERY_KEY_FAIL: {
            return Object.assign({}, state, {
                sendPasswordRecoveryKeyLoading: false,
                sendPasswordRecoveryKeyLoaded: false,
                sendPasswordRecoveryKeyFail: true,
                sendPasswordRecoveryKeyData: null,
                sendPasswordRecoveryKeyErrMessage: null,
            });
        }
        case actions.ActionTypes.CLEAR_SEND_PASSWORD_RECOVERY_KEY_STATE: {
            return Object.assign({}, state, {
                sendPasswordRecoveryKeyLoading: false,
                sendPasswordRecoveryKeyLoaded: false,
                sendPasswordRecoveryKeyFail: false,
                sendPasswordRecoveryKeyData: null,
                sendPasswordRecoveryKeyErrMessage: null,
            });
        }

        case actions.ActionTypes.CHANGE_PASSWORD: {
            return Object.assign({}, state, {
                changePasswordLoading: true,
                changePasswordLoaded: false,
                changePasswordFail: false,
                changePasswordData: null,
                changePasswordErrMessage: null,
            });
        }
        case actions.ActionTypes.CHANGE_PASSWORD_SUCCESS: {
            const err = !payload.success;
            const errMes = err && payload.message ? payload.message : null;
            // console.log(payload);
            return Object.assign({}, state, {
                changePasswordLoading: false,
                changePasswordLoaded: true,
                changePasswordFail: false,
                changePasswordData: !err ? payload : null,
                changePasswordErrMessage: errMes,
            });
        }
        case actions.ActionTypes.CHANGE_PASSWORD_FAIL: {
            return Object.assign({}, state, {
                changePasswordLoading: false,
                changePasswordLoaded: false,
                changePasswordFail: true,
                changePasswordData: null,
                changePasswordErrMessage: null,
            });
        }
        case actions.ActionTypes.CLEAR_CHANGE_PASSWORD_STATE: {
            return Object.assign({}, state, {
                changePasswordLoading: false,
                changePasswordLoaded: false,
                changePasswordFail: false,
                changePasswordData: null,
                changePasswordErrMessage: null,
            });
        }

        case actions.ActionTypes.DO_LOGOUT: {
            return Object.assign({}, state, {
                doLogOutEmitted: true,
                currentUserToken: null,
                // currentUserRole: null,
            });
        }
        case actions.ActionTypes.DO_LOGOUT_SUCCESS: {
            return Object.assign({}, state, {
                doLogOutEmitted: false
            });
        }

        case actions.ActionTypes.GET_CURRENT_USER: {
            return Object.assign({}, state, {
                getCurrentUserLoading: true,
                getCurrentUserLoaded: false,
                getCurrentUserFail: false,
                getCurrentUserData: null,
            });
        }
        case actions.ActionTypes.GET_CURRENT_USER_SUCCESS: {
            let userInfo: UserModel = null;
            if (payload && payload.data && payload.success) {
                userInfo = new UserModel(payload.data);
            }

            return Object.assign({}, state, {
                getCurrentUserLoading: false,
                getCurrentUserLoaded: true,
                getCurrentUserFail: false,
                getCurrentUserData: userInfo ? userInfo : null,
                currentUserRole: userInfo ? userInfo.role : null,
            });
        }
        case actions.ActionTypes.GET_CURRENT_USER_FAIL: {
            return Object.assign({}, state, {
                getCurrentUserLoading: false,
                getCurrentUserLoaded: false,
                getCurrentUserFail: true,
                getCurrentUserData: null,
            });
        }
        case actions.ActionTypes.CLEAR_GET_CURRENT_USER_STATE: {
            return Object.assign({}, state, {
                getCurrentUserLoading: false,
                getCurrentUserLoaded: false,
                getCurrentUserFail: false,
                getCurrentUserData: null,
            });
        }


        default: {
            return state;
        }
    }
}

export const registerUserLoading = (state: AccountState) => state.registerUserLoading;
export const registerUserLoaded = (state: AccountState) => state.registerUserLoaded;
export const registerUserFail = (state: AccountState) => state.registerUserFail;
export const registerUserData = (state: AccountState) => state.registerUserData;
export const registerUserErrors = (state: AccountState) => state.registerUserErrors;

export const loginUserLoading = (state: AccountState) => state.loginUserLoading;
export const loginUserLoaded = (state: AccountState) => state.loginUserLoaded;
export const loginUserFail = (state: AccountState) => state.loginUserFail;
export const loginUserData = (state: AccountState) => state.loginUserData;
export const loginUserErrors = (state: AccountState) => state.loginUserErrors;

export const sendPasswordRecoveryKeyLoading = (state: AccountState) => state.sendPasswordRecoveryKeyLoading;
export const sendPasswordRecoveryKeyLoaded = (state: AccountState) => state.sendPasswordRecoveryKeyLoaded;
export const sendPasswordRecoveryKeyFail = (state: AccountState) => state.sendPasswordRecoveryKeyFail;
export const sendPasswordRecoveryKeyData = (state: AccountState) => state.sendPasswordRecoveryKeyData;
export const sendPasswordRecoveryKeyErrMessage = (state: AccountState) => state.sendPasswordRecoveryKeyErrMessage;

export const changePasswordLoading = (state: AccountState) => state.changePasswordLoading;
export const changePasswordLoaded = (state: AccountState) => state.changePasswordLoaded;
export const changePasswordFail = (state: AccountState) => state.changePasswordFail;
export const changePasswordData = (state: AccountState) => state.changePasswordData;
export const changePasswordErrMessage = (state: AccountState) => state.changePasswordErrMessage;

export const lastLoginedUserInfo = (state: AccountState) => state.lastLoginedUserInfo;
export const currentUserToken = (state: AccountState) => state.currentUserToken;
export const currentUserRole = (state: AccountState) => state.currentUserRole;

export const doLogOutEmitted = (state: AccountState) => state.doLogOutEmitted;

export const getCurrentUserLoading = (state: AccountState) => state.getCurrentUserLoading;
export const getCurrentUserLoaded = (state: AccountState) => state.getCurrentUserLoaded;
export const getCurrentUserFail = (state: AccountState) => state.getCurrentUserFail;
export const getCurrentUserData = (state: AccountState) => state.getCurrentUserData;

// export const currentShopName = (state: AccountState) => state.currentShopName;

