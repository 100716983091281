import { Action } from '@ngrx/store';
import { type } from '../shared/utility/utilityHelper';

export const ActionTypes = {
    ADD_BANNER: type('ADD_BANNER'),
    ADD_BANNER_SUCCESS: type('ADD_BANNER_SUCCESS'),
    ADD_BANNER_FAIL: type('ADD_BANNER_FAIL'),
    CLEAR_ADD_BANNER_STATE: type('CLEAR_ADD_BANNER_STATE'),

    UPDATE_BANNER: type('UPDATE_BANNER'),
    UPDATE_BANNER_SUCCESS: type('UPDATE_BANNER_SUCCESS'),
    UPDATE_BANNER_FAIL: type('UPDATE_BANNER_FAIL'),
    CLEAR_UPDATE_BANNER_ITEM_STATE: type('CLEAR_UPDATE_BANNER_ITEM_STATE'),

    GET_BANNER_ITEM: type('GET_BANNER_ITEM'),
    GET_BANNER_ITEM_SUCCES: type('GET_BANNER_ITEM_SUCCESS'),
    GET_BANNER_ITEM_FAIL: type('GET_BANNER_ITEM_FAIL'),

    DELETE_BANNER_ITEM: type('DELETE_BANNER_ITEM'),
    DELETE_BANNER_ITEM_SUCCESS: type('DELETE_BANNER_ITEM_SUCCESS'),
    DELETE_BANNER_ITEM_FAIL: type('DELETE_BANNER_ITEM_FAIL'),
    CLEAR_DELETE_BANNER_ITEM_STATE: type('CLEAR_DELETE_BANNER_ITEM_STATE'),

    GET_BANNER_LIST: type('GET_BANNER_LIST'),
    GET_BANNER_LIST_SUCCESS: type('GET_BANNER_LIST_SUCCESS'),
    GET_BANNER_LIST_FAIL: type('GET_BANNER_LIST_FAIL'),

    ADD_BANNER_SLIDER: type('ADD_BANNER_SLIDER'),
    ADD_BANNER_SLIDER_SUCESS: type('ADD_BANNER_SLIDER_SLIDER'),
    ADD_BANNER_SLIDER_FAIL: type('ADD_BANNER_SLIDER_FAIL'),
    CLEAR_ADD_BANNER_SLIDER_STATE: type('CLEAR_ADD_BANNER_SLIDER_STATE'),

    DELETE_BANNER_SLIDER: type('DELETE_BANNER_SLIDER'),
    DELETE_BANNER_SLIDER_SUCCESS: type('DELETE_BANNER_SLIDER_SUCCESS'),
    DELETE_BANNER_SLIDER_FAIL: type('DELETE_BANNER_SLIDER_FAIL'),

    GET_BANNER_SLIDER_LIST: type('GET_BANNER_SLIDER_LIST'),
    GET_BANNER_SLIDER_LIST_SUCCESS: type('GET_BANNER_SLIDER_LIST_SUCCESS'),
    GET_BANNER_SLIDER_LIST_FAIL: type('GET_BANNER_SLIDER_LIST_FAIL'),

    UPDATE_BANNER_SLIDER_ITEM: type('UPDATE_BANNER_SLIDER_ITEM'),
    UPDATE_BANNER_SLIDER_ITEM_SUCCESS: type('UPDATE_BANNER_SLIDER_ITEM_SUCCESS'),
    UPDATE_BANNER_SLIDER_ITEM_FAIL: type('UPDATE_BANNER_SLIDER_ITEM_FAIL'),
    CLEAR_UPDATE_BANNER_SLIDET_ITEM_STATE: type('CLEAR_UPDATE_BANNER_SLIDET_ITEM_STATE'),

    GET_BANNER_SLIDER_ITEM: type('GET_BANNER_SLIDER_ITEM'),
    GET_BANNER_SLIDER_ITEM_SUCCESS: type('GET_BANNER_SLIDER_ITEM_SUCCESS'),
    GET_BANNER_SLIDER_ITEM_FAIL: type('GET_BANNER_SLIDER_ITEM_FAIL'),
};

export class AddBannerAction implements Action {
    type = ActionTypes.ADD_BANNER;
    constructor(public payload: any) { }
}
export class AddBannerFailAction implements Action {
    type = ActionTypes.ADD_BANNER_FAIL;
    constructor(public payload: any) { }
}
export class AddBannerSuccessAction implements Action {
    type = ActionTypes.ADD_BANNER_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearAddBannerStateAction implements Action {
    type = ActionTypes.CLEAR_ADD_BANNER_STATE;
    constructor(public payload: any) { }
}
export class UpdateBannerAction implements Action {
    type = ActionTypes.UPDATE_BANNER;
    constructor(public payload: any) { }
}
export class UpdateBannerFailAction implements Action {
    type = ActionTypes.UPDATE_BANNER_FAIL;
    constructor(public payload: any) { }
}
export class UpdateBannerSuccessAction implements Action {
    type = ActionTypes.UPDATE_BANNER_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUpdateBannerItemStateAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_BANNER_ITEM_STATE;
    constructor(public payload: any) { }
}

export class GetBannerItemAction implements Action {
    type = ActionTypes.GET_BANNER_ITEM;
    constructor(public payload: any) { }
}
export class GetBannerItemFailAction implements Action {
    type = ActionTypes.GET_BANNER_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class GetBannerItemSuccessAction implements Action {
    type = ActionTypes.GET_BANNER_ITEM_SUCCES;
    constructor(public payload: any) { }
}

export class DeleteBannerItemAction implements Action {
    type = ActionTypes.DELETE_BANNER_ITEM;
    constructor(public payload: any) { }
}
export class DeleteBannerItemFailAction implements Action {
    type = ActionTypes.DELETE_BANNER_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class DeleteBannerItemSuccessAction implements Action {
    type = ActionTypes.DELETE_BANNER_ITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearDeleteBannerItemStateAction implements Action {
    type = ActionTypes.CLEAR_DELETE_BANNER_ITEM_STATE;
    constructor(public payload: any) { }
}

export class GetBannerListAction implements Action {
    type = ActionTypes.GET_BANNER_LIST;
    constructor(public payload: any) { }
}
export class GetBannerListFailAction implements Action {
    type = ActionTypes.GET_BANNER_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetBannerListSuccessAction implements Action {
    type = ActionTypes.GET_BANNER_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class AddBannerSliderAction implements Action {
    type = ActionTypes.ADD_BANNER_SLIDER;
    constructor(public payload: any) { }
}
export class AddBannerSliderSuccessAction implements Action {
    type = ActionTypes.ADD_BANNER_SLIDER_SUCESS;
    constructor(public payload: any) { }
}
export class AddBannerSliderFailAction implements Action {
    type = ActionTypes.ADD_BANNER_SLIDER_FAIL;
    constructor(public payload: any) { }
}
export class ClearAddBannerSliderStateAction implements Action {
    type = ActionTypes.CLEAR_ADD_BANNER_SLIDER_STATE;
    constructor(public payload: any) { }
}

export class DeleteBannerSliderAction implements Action {
    type = ActionTypes.DELETE_BANNER_SLIDER;
    constructor(public payload: any) { }
}
export class DeleteBannerSliderFailAction implements Action {
    type = ActionTypes.DELETE_BANNER_SLIDER_FAIL;
    constructor(public payload: any) { }
}
export class DeleteBannerSliderSuccessAction implements Action {
    type = ActionTypes.DELETE_BANNER_SLIDER_SUCCESS;
    constructor(public payload: any) { }
}

export class GetBannerSliderListAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_LIST;
    constructor(public payload: any) { }
}
export class GetBannerSliderListFailAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetBannerSliderListSuccessAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateBannerSliderItemAction implements Action {
    type = ActionTypes.UPDATE_BANNER_SLIDER_ITEM;
    constructor(public payload: any) { }
}
export class UpdateBannerSliderItemFailAction implements Action {
    type = ActionTypes.UPDATE_BANNER_SLIDER_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class UpdateBannerSliderItemSuccessAction implements Action {
    type = ActionTypes.UPDATE_BANNER_SLIDER_ITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUpdateBannerSliderItemStateAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_BANNER_SLIDET_ITEM_STATE;
    constructor(public payload: any) { }
}

export class GetBannerSliderItemAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_ITEM;
    constructor(public payload: any) { }
}
export class GetBannerSliderItemFailAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class GetBannerSliderItemSuccessAction implements Action {
    type = ActionTypes.GET_BANNER_SLIDER_ITEM_SUCCESS;
    constructor(public payload: any) { }
}

export type Actions =
    | AddBannerAction
    | AddBannerFailAction
    | AddBannerSuccessAction
    | ClearAddBannerStateAction
    | UpdateBannerAction
    | UpdateBannerFailAction
    | UpdateBannerSuccessAction
    | ClearUpdateBannerItemStateAction
    | GetBannerItemAction
    | GetBannerItemFailAction
    | GetBannerItemSuccessAction
    | DeleteBannerItemAction
    | DeleteBannerItemFailAction
    | DeleteBannerItemSuccessAction
    | ClearDeleteBannerItemStateAction
    | GetBannerListAction
    | GetBannerListFailAction
    | GetBannerListSuccessAction
    | AddBannerSliderAction
    | AddBannerSliderFailAction
    | AddBannerSliderSuccessAction
    | ClearAddBannerSliderStateAction
    | DeleteBannerSliderAction
    | DeleteBannerSliderFailAction
    | DeleteBannerSliderSuccessAction
    | GetBannerSliderListAction
    | GetBannerSliderListFailAction
    | GetBannerSliderListSuccessAction
    | UpdateBannerSliderItemAction
    | UpdateBannerSliderItemFailAction
    | UpdateBannerSliderItemSuccessAction
    | ClearUpdateBannerSliderItemStateAction
    | GetBannerSliderItemAction
    | GetBannerSliderItemFailAction
    | GetBannerSliderItemSuccessAction;



