import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, ValidationErrors, ValidatorFn, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { YoutubeLinkValidators } from "../add-video-dialog/add-video-dialog.component";
import { ProductSelectDialogComponent } from "../product-select/product-select.dialog";
import { ProductModel } from "app/core/common/models/product.model";
import { CommonSandbox } from "app/core/common/common.sandbox";
import { Subscription } from "rxjs";
import { ShopSandbox } from "app/core/shop/shop.sandbox";

@Component({
    templateUrl: './product-group-editor.component.html',
    styleUrls: ['./product-group-editor.component.scss']
})
export class ProductGroupEditorDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    editedItem: any;

    form: FormGroup;
    title: FormControl = new FormControl('', Validators.required);
    url: FormControl = new FormControl('', [Validators.required, YoutubeLinkValidators.validUrl]);

    selectedProducts: ProductModel[] = [];
    idShopname = '';

    constructor(
        private dialogRef: MatDialogRef<ProductGroupEditorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private commonSandbox: CommonSandbox,
        private shopSandbox: ShopSandbox
    ) {
        this.commonSandbox.doClearUpdateProductsGroupItemState();

        if (this.data) {
            this.editedItem = this.data;
            console.log(this.data)
        }
    }

    ngOnInit(): void {
        

        this.form = this.fb.group({
            title: this.title,
            url: this.url
        })

        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            if (shopName) {
                console.log('shopName', shopName)
                this.idShopname = shopName || '';

                if (this.editedItem) {
                    this.pathEditedData();
                }
            }
        }));

        this.subs.push(this.commonSandbox.updateProductGroupItemData$.subscribe(res => {
            if (res && this.editedItem) {
                this.commonSandbox.doClearUpdateProductsGroupItemState();
                this.dialogRef.close(true)
            }
        }))
        this.subs.push(this.commonSandbox.updateProductGroupItemFailData$.subscribe(res => {
            if (res && this.editedItem) {
                this.commonSandbox.doClearUpdateProductsGroupItemState();
                this.dialogRef.close(false)
            }
        }));
        this.subs.push(this.commonSandbox.getPrestashopProductsListInData$.subscribe(res => {
            if (res && !!res.length && this.editedItem) {
                res.map(item => {
                    const isAvailable = this.editedItem.products.find(el => el === item.id);
                    if (isAvailable){
                        this.selectedProducts.push(item);
                    }
                })
            }
        }))
    }

    private pathEditedData(): void {
        this.title.patchValue(this.editedItem.title || '');
        this.url.patchValue(this.editedItem.video_link || '');

        const params: any = {
            idShopname: this.idShopname,
            keyword: '',
            limit: 100,
            offset: 0,
            id: this.editedItem.products.join(',') || []
        }
        console.log('params', params)
        this.commonSandbox.doGetPrestashopProductsListIn(params);
    }

    remove(item): void {
        this.selectedProducts.splice(this.selectedProducts.indexOf(item), 1);
    }

    submit(): void {
        if (this.form.invalid) {
            return;
        }
        const params: any = {
            "description": "",
            "idShopname": "",
            "position": 0,
            "products": this.selectedProducts.map(el => el.id),
            "title": this.title.value,
            "videoExtraData": "",
            "videoLink": this.url.value
        };
        if (this.editedItem) {
            params.id = this.editedItem.id || '';

            this.commonSandbox.doUpdateProductsGroupItem(this.editedItem.id, params);
        } else {
            this.dialogRef.close(params);
        }

    }

    addProduct(): void {
        this.dialog.open(ProductSelectDialogComponent).afterClosed().subscribe(res => {
            if (res && res.id) {
                const isAvailable = this.selectedProducts.find(el => el.id === res.id)
                if (!isAvailable) {
                    this.selectedProducts.push(res);
                    console.log(res)
                }
            }
        })
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe())
    }
}