import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../state.interface';

import * as actions from './root.actions';
import {
    getUsersListLoading,
    getUsersListLoaded,
    getUsersListFail,
    getUsersListData,
    getClustersListLoading,
    getClustersListLoaded,
    getClustersListFail,
    getClustersListData,
    getShopClusterLoading,
    getShopClusterLoaded,
    getShopClusterFail,
    getShopClusterData,
    createUserLoading,
    createUserLoaded,
    createUserFail,
    createUserData,
    createUserErrMess,
    clusterSaveLoading,
    clusterSaveLoaded,
    clusterSaveFail,
    clusterSaveData,
    clusterSaveMsg,
    updateUserLoading,
    updateUserLoaded,
    updateUserFail,
    updateUserData,
    wipeDataLoading,
    wipeDataLoaded,
    wipeDataFail,
    wipeDataData
} from './root.selector';

@Injectable()
export class RootSandbox {
    public getUsersListLoading$ = this.appState$.select(getUsersListLoading);
    public getUsersListLoaded$ = this.appState$.select(getUsersListLoaded);
    public getUsersListFail$ = this.appState$.select(getUsersListFail);
    public getUsersListData$ = this.appState$.select(getUsersListData);

    public createUserLoading$ = this.appState$.select(createUserLoading);
    public createUserLoaded$ = this.appState$.select(createUserLoaded);
    public createUserFail$ = this.appState$.select(createUserFail);
    public createUserData$ = this.appState$.select(createUserData);
    public createUserErrMess$ = this.appState$.select(createUserErrMess);

    public getClustersListLoading$ = this.appState$.select(getClustersListLoading);
    public getClustersListLoaded$ = this.appState$.select(getClustersListLoaded);
    public getClustersListFail$ = this.appState$.select(getClustersListFail);
    public getClustersListData$ = this.appState$.select(getClustersListData);

    public getShopClusterLoading$ = this.appState$.select(getShopClusterLoading);
    public getShopClusterLoaded$ = this.appState$.select(getShopClusterLoaded);
    public getShopClusterFail$ = this.appState$.select(getShopClusterFail);
    public getShopClusterData$ = this.appState$.select(getShopClusterData);

    public clusterSaveLoading$ = this.appState$.select(clusterSaveLoading);
    public clusterSaveLoaded$ = this.appState$.select(clusterSaveLoaded);
    public clusterSaveFail$ = this.appState$.select(clusterSaveFail);
    public clusterSaveData$ = this.appState$.select(clusterSaveData);
    public clusterSaveMsg$ = this.appState$.select(clusterSaveMsg);

    public updateUserLoading$ = this.appState$.select(updateUserLoading);
    public updateUserLoaded$ = this.appState$.select(updateUserLoaded);
    public updateUserFail$ = this.appState$.select(updateUserFail);
    public updateUserData$ = this.appState$.select(updateUserData);

    public wipeDataLoading$ = this.appState$.select(wipeDataLoading);
    public wipeDataLoaded$ = this.appState$.select(wipeDataLoaded);
    public wipeDataFail$ = this.appState$.select(wipeDataFail);
    public wipeDataData$ = this.appState$.select(wipeDataData);

    constructor(protected appState$: Store<store.AppState>) { }

    doGetUsersList(params: any): void {
        this.appState$.dispatch(new actions.GetUsersListAction(params));
    }

    doCreateUser(params: any): void {
        this.appState$.dispatch(new actions.CreateUserAction(params));
    }
    doClearCreateUser(): void {
        this.appState$.dispatch(new actions.ClearCreateUserStateAction({}));
    }

    doGetClustersList(params: any): void {
        this.appState$.dispatch(new actions.GetClustersListAction(params));
    }

    doGetShopCluster(params: any): void {
        this.appState$.dispatch(new actions.GetShopClusterAction(params));
    }

    doClusterSave(params: any): void {
        this.appState$.dispatch(new actions.ClusterSaveAction(params));
    }
    doClearClusterSaveState(): void {
        this.appState$.dispatch(new actions.ClearClusterSaveStateAction({}));
    }

    doUpdateUser(params: any): void {
        this.appState$.dispatch(new actions.UpdateUserAction(params));
    }
    doClearUpdateUserState(): void {
        this.appState$.dispatch(new actions.ClearUpdateUserStateAction({}));
    }

    doWipeData(params: any): void {
        this.appState$.dispatch(new actions.WipeDataAction(params));
    }
    doClearWipeDataState(): void {
        this.appState$.dispatch(new actions.ClearWipeDataStateAction({}));
    }
}

