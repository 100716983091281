import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AccountSandbox } from "app/core/account/account.sandbox";
import { USER_ROLES } from "app/core/shared/helpers/constants.enum";
import { map, Observable, skipWhile } from "rxjs";

@Injectable({providedIn: 'root'})
export class RootGuard implements CanActivate {
    private currentRole: string | null = null;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private accountSandbox: AccountSandbox
    ) {
        // this.accountSandbox.currentUserRole$.pipe(map((value: string, index: number): boolean => value ? true : false)).subscribe(bool => console.log(bool));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.accountSandbox.currentUserRole$.pipe(
            skipWhile(role => !role), 
            map((role:string) => {
                
                if(!!role){
                        this.currentRole = role;
                        if(this.currentRole && (this.currentRole === USER_ROLES.admin || this.currentRole === USER_ROLES.root)){ 
                            return true
                            }  
                    }else {
                    
                        this.snackBar.open(
                            'Unauthorized!', 'Close',
                            {
                                horizontalPosition: 'end',
                                verticalPosition: 'bottom',
                                duration: 3000
                            }
                            );
            
                        this.router.navigate(['/']);
                        return false;
                        }          
                    }   
                ));
    }
}