import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, retry } from 'rxjs/operators';
import * as actions from './shop.actions';
import { ShopService } from './shop.service';

@Injectable()
export class ShopEffect {
    constructor(
        private actions$: Actions,
        private shopService: ShopService
    ) { }

    addShop$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.ADD_SHOP),
        map((action: actions.AddShopAction) => action.payload),
        switchMap(state => {
            return this.shopService.addShop(state).pipe(
                map(data => new actions.AddShopSuccessAction(data)),
                catchError(err => of(new actions.AddShopFailAction(err)))
            );
        })
    ));

    getShopSettings$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_SHOP_SETTINGS),
        map((action: actions.GetShopSettingsAction) => action.payload),
        switchMap(state => {
            return this.shopService.getShopSettings(state).pipe(
                map(data => new actions.GetShopSettingsSuccessAction(data)),
                catchError(err => of(new actions.GetShopSettingsFailAction(err)))
            );
        })
    ));

    updateMenuItemAction$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_MENU_ITEM),
        map((action: actions.UpdateMenuItemAction) => action.payload),
        switchMap(state => {
            return this.shopService.updateMenuItem(state).pipe(
                map(data => new actions.UpdateMenuItemSuccessAction(data)),
                catchError(err => of(new actions.UpdateMenuItemFailAction(err)))
            );
        })
    ));

    getMenuItemAction$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_MENU_ITEM),
        map((action: actions.GetMenuItemAction) => action.payload),
        switchMap(state => {
            return this.shopService.getMenuItem(state).pipe(
                map(data => new actions.GetMenuItemSuccessAction(data)),
                catchError(err => of(new actions.GetMenuItemFailAction(err)))
            );
        })
    ));

    getOrderStatusList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_ORDER_STATUS_LIST),
        map((action: actions.GetOrderStatusListAction) => action.payload),
        switchMap(state => {
            return this.shopService.getOrderStatusList(state).pipe(
                map(data => new actions.GetOrderStatusListSuccessAction(data)),
                catchError(err => of(new actions.GetOrderStatusListFailAction(err)))
            );
        })
    ));

    getShopLangList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_SHOP_LANG_LIST),
        map((action: actions.GetShopLangListAction) => action.payload),
        switchMap(state => {
            return this.shopService.getShopLangList(state).pipe(
                map(data => new actions.GetShopLangListSuccessAction(data)),
                catchError(err => of(new actions.GetShopLangListFailAction(err)))
            );
        })
    ));

    getBrandsList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_BRANDS_LIST),
        map((action: actions.GetBrandsListAction) => action.payload),
        switchMap(state => {
            return this.shopService.getBrandsList(state).pipe(
                map(data => new actions.GetBrandsListSuccessAction(data)),
                catchError(err => of(new actions.GetBrandsListFailAction(err)))
            );
        })
    ));

    createPaypalHook$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.CREATE_PAYPAL_HOOK),
        map((action: actions.CreatePaypalHookAction) => action.payload),
        switchMap(state => {
            return this.shopService.createPaypalHook(state).pipe(
                map(data => new actions.CreatePaypalHookSuccessAction(data)),
                catchError(err => of(new actions.CreatePaypalHookFailAction(err)))
            );
        })
    ));

    createStripeHook$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.CREATE_STRIPE_HOOK),
        map((action: actions.CreateStripeHookAction) => action.payload),
        switchMap(state => {
            return this.shopService.createStripeHook(state).pipe(
                map(data => new actions.CreateStripeHookSuccessAction(data)),
                catchError(err => of(new actions.CreateStripeHookFailAction(err)))
            );
        })
    ));

    getShopErrorList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_SHOP_ERRORS_LIST),
        map((action: actions.GetShopErrorListAction) => action.payload),
        switchMap(state => {
            return this.shopService.getShopErrorList(state).pipe(
                map(data => new actions.GetShopErrorListSuccessAction(data)),
                catchError(err => of(new actions.GetShopErrorListFailAction(err)))
            );
        })
    ));

    updateErrorStatus$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.UPDATE_ERROR_STATUS),
        map((action: actions.UpdateErrorStatusAction) => action.payload),
        switchMap(state => {
            return this.shopService.updateErrorStatus(state).pipe(
                map(data => new actions.UpdateErrorStatusSuccessAction(data)),
                catchError(err => of(new actions.UpdateErrorStatusFailAction(err)))
            );
        })
    ));

    getCurrencylist$ = createEffect(() => this.actions$.pipe(
        ofType(actions.ActionTypes.GET_CURRENCY_LIST),
        map((action: actions.GetCurrencyListAction) => action.payload),
        switchMap(state => {
            return this.shopService.getCurrencyList(state).pipe(
                map(data => new actions.GetCurrencyListSuccessAction(data)),
                catchError(err => of(new actions.GetCurrencyListFailAction(err)))
            );
        })
    ));
}

