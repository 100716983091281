import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { CategoryModel } from 'app/core/common/models/category.model';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './category-select.dialog.html',
    styleUrls: ['./category-select.dialog.scss']
})
export class CategorySelectDialogComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];

    myControl = new FormControl();
    idShopname = '';
    categoryList: CategoryModel[] = [];

    constructor(
        private dialogRef: MatDialogRef<CategorySelectDialogComponent>,
        public commonSandbox: CommonSandbox,
        private shopSandbox: ShopSandbox,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.idShopname = shopName || '';
            this.getCategories();
        }));
        this.subs.push(this.commonSandbox.getCategoryListData$.subscribe(res => {
            if (res && !!res.length) {
                this.categoryList = res.filter((el: any) => el.id !== 1);
            } else {
                this.categoryList = [];
            }
        }));
        this.myControl.valueChanges.subscribe(res => {
            this.getCategories(res);
        });
    }

    private getCategories(key = ''): void {
        if (!this.idShopname) {
            return;
        }
        // console.log(this.translate.currentLang);
        const params: any = {
            keyword: key,
            lng: this.translate.currentLang,
            limit: '40',
            offset: '0',
            idShopname: this.idShopname
        };

        this.commonSandbox.doGetCategoryList(params);
    }

    select(product: CategoryModel): void {
        // console.log(product);
        this.dialogRef.close(product);
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}

