<h1 mat-dialog-title>{{'Products group' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field appearance="fill" style="width: 360px;">
            <mat-label>{{'Title' | translate}}:</mat-label>
            <input matInput [formControl]="title">
            <mat-error *ngIf="title.invalid">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 360px;">
            <mat-label>{{'Video url' | translate}}:</mat-label>
            <input matInput [formControl]="url">
            <mat-hint>https://www.youtube.com/</mat-hint>
            <mat-error *ngIf="url.invalid">
                {{'Please enter a valid url address' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
    <div>
        <mat-label>{{'Products' | translate}}:</mat-label>
        <div style="margin-bottom: 16px;">
            <mat-selection-list [multiple]="false">
                <mat-list-option *ngFor="let product of selectedProducts" (click)="remove(product)">
                    <div style="display: flex; align-items: center;">
                        <mat-icon inline>close</mat-icon>
                        <span style="padding-left: 8px;">
                            {{product.titlelang}}
                        </span>
                    </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div *ngIf="!selectedProducts || !selectedProducts.length" style="color: grey; margin-bottom: 16px;">
            {{'No data...' | translate}}
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="addProduct()">
                {{'Add product' | translate}}
            </button>
        </div>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="submit()" [disabled]="form.invalid">
        {{'Submit' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="null" cdkFocusInitial>
        {{'Close' | translate}}
    </button>
</div>