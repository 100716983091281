/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'General',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.initialization',
                title: 'Initialization',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/initialization'
            },
            {
                id: 'dashboards.shop-languages',
                title: 'Shop languages',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/shop-languages'
            },
            {
                id: 'dashboards.errors',
                title: 'Errors',
                type: 'basic',
                icon: 'heroicons_outline:cash',
                link: '/errors'
            },
        ]
    },
    {
        id: 'dashboards',
        title: 'Modules',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.banner',
                title: 'Banner',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/banner'
            },
            {
                id: 'dashboards.menu',
                title: 'Menu',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/menu'
            },
            {
                id: 'dashboards.notification',
                title: 'Notifications',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/notification'
            },
            {
                id: 'dashboards.templates',
                title: 'Templates',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/templates'
            }
        ]
    },
    {
        id: 'root',
        title: 'Root',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'root.user-list',
                title: 'Users list',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/user-list'
            },
            {
                id: 'root.cluster-list',
                title: 'Cluster-list',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/cluster-list'
            }
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
