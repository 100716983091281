import { ShopSettingsModel } from "app/core/shop/models/shop-settings.model";

export class VerifyAppSettings {
    static Verify(settings: ShopSettingsModel): VerifyError[] {
        if (!settings) {
            return [];
        }

        const errors: VerifyError[] = [];

        if (!settings?.appsettings?.androidId || !settings?.appsettings?.appleBundleId || !settings?.appsettings?.applePrefixId) {
            errors.push(new VerifyError('/initialization', 'Fill Android & Apple applications ID!'));
        }
        if (!!settings?.appsettings?.paymentsList?.length) {
            const isStripe = settings?.appsettings?.paymentsList.find(el => el === 'stripe_official');
            if (isStripe && settings?.stripedata?.live?.publicKey && settings?.stripedata?.live?.secretKey && settings?.stripedata?.live?.orderState) {
                // ok
            } else {
                errors.push(new VerifyError('/initialization', 'Fill Stripe Live credentials!'))
            }
            if (isStripe && settings?.stripedata?.test?.publicKey && settings?.stripedata?.test?.secretKey && settings?.stripedata?.test?.orderState) {
                // ok
            } else {
                errors.push(new VerifyError('/initialization', 'Fill Stripe Test credentials!'))
            }

            const isPaypal = settings?.appsettings?.paymentsList.find(el => el === 'paypal');
            if (isPaypal && settings?.paypaldata?.live?.clientId && settings?.paypaldata?.live?.secret && settings?.paypaldata?.live?.orderState) {
                // ok
            } else {
                errors.push(new VerifyError('/initialization', 'Fill Paypal Live credentials!'))
            }
            if (isPaypal && settings?.paypaldata?.sandbox?.clientId && settings?.paypaldata?.sandbox?.secret && settings?.paypaldata?.sandbox?.orderState) {
                // ok
            } else {
                errors.push(new VerifyError('/initialization', 'Fill Paypal Sandbox credentials!'))
            }
        } else {
            errors.push(new VerifyError('/initialization', 'Needs to select payment methods!'))
        }

        if (!settings?.appsettings?.lngList?.length) {
            errors.push(new VerifyError('/shop-languages', 'Needs to select App language!'))
        }
        if (!settings?.appsettings?.isocurrency) {
            errors.push(new VerifyError('/currency-list', 'Needs to select App currency!'))
        }

        return errors;
    }
}

export class VerifyError {
    constructor(
        public link: string,
        public title: string
    ) { }
}