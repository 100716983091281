<h1 mat-dialog-title>{{'Create user' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{'Name' | translate}}</mat-label>
            <input matInput placeholder="{{'Name' | translate}}" type="text" required [formControl]="name">
            <mat-icon class="icon-size-5" matPrefix>notes</mat-icon>
            <mat-error *ngIf="name.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Email' | translate}}</mat-label>
            <input matInput placeholder="{{'Email' | translate}}" type="email" required [formControl]="email">
            <mat-icon class="icon-size-5" matPrefix>email</mat-icon>
            <mat-error *ngIf="email.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Password' | translate}}</mat-label>
            <input matInput placeholder="{{'Password' | translate}}" type="password" [required]="!data" [formControl]="password">
            <mat-icon class="icon-size-5" matPrefix>security</mat-icon>
            <mat-error *ngIf="password.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{'Role' | translate}}</mat-label>
            <mat-select [formControl]="role">
                <mat-option [value]="USER_ROLES.user">
                    {{'User' | translate}}
                </mat-option>
                <mat-option [value]="USER_ROLES.admin">
                    {{'Admin' | translate}}
                </mat-option>
                <mat-option [value]="USER_ROLES.root">
                    {{'Root' | translate}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="role.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'Plan' | translate}}</mat-label>
            <mat-select [formControl]="plan">
                <mat-option [value]="ACCOUNT_VERSION.standart">
                    {{'Standart' | translate}}
                </mat-option>
                <mat-option [value]="ACCOUNT_VERSION.pro">
                    {{'Pro' | translate}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="role.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'Cluster Id' | translate}}</mat-label>
            <mat-select [formControl]="clusterId">
                <mat-option [value]="''">
                    {{'Any' | translate}}
                </mat-option>
                <mat-option *ngFor="let cluster of clustersList" [value]="cluster.cluster_id || null">
                    {{cluster.cluster_name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="role.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
    </form>

</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="confirm()" [disabled]="form.invalid">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Create' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Edit' | translate}}
        </ng-container>
    </button>
    <button mat-raised-button color="primary" (click)="cancel()">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Cancel' | translate}}
    </button>
</div>