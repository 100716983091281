import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SnackService {
    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService
    ) { }

    public log(message: string, delay = 1500): void {
        this.translateService.get(message).subscribe(res => {
            this.snackBar.open(
                res, 'Ok',
                {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: delay,
                }
            );
        })
    }
}

