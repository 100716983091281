export class CustomerModel {
    public active: number;
    public ape: string;
    public associations: any;
    public birthday: string;
    public company: string;
    public date_add: string;
    public date_upd: string;
    public deleted: string;
    public email: string;
    public firstname: string;
    public id: number;
    public id_default_group: string;
    public id_gender: string;
    public id_lang: string;
    public id_risk: string;
    public id_shop: string;
    public id_shop_group: string;
    public ip_registration_newsletter: string;
    public is_guest: string;
    public last_passwd_gen: string;
    public lastname: string;

    constructor(res: any) {
        this.active = +res.active;
        this.ape = res.ape;
        this.associations = res.associations;
        this.birthday = res.birthday;
        this.company = res.company;
        this.date_add = res.date_add;
        this.date_upd = res.date_upd;
        this.deleted = res.deleted;
        this.email = res.email;
        this.firstname = res.firstname;
        this.id = res.id;
        this.id_default_group = res.id_default_group;
        this.id_gender = res.id_gender;
        this.id_lang= res.id_lang;
        this.id_risk =res.id_risk;
        this.id_shop = res.id_shop;
        this.id_shop_group = res.id_shop_group;
        this.ip_registration_newsletter = res.ip_registration_newsletter;
        this.is_guest = res.is_guest;
        this.last_passwd_gen = res.last_passwd_gen;
        this.lastname = res.lastname;
    }
}

