import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '../api';

@Injectable()
export class AccountService extends Api {
    // private apiUrl: string = this.getApiUrl() + this.getApiVersion();
    private rootUrl: string = this.getRootApi() + this.getApiVersion();

    constructor(private http: HttpClient) {
        super();
    }

    public registerUser(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/signup', params);
    }

    public loginUser(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/signin', params);
    }

    public sendPasswordRecoveryKey(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/reset-password', params);
    }

    public changePassword(params: any): Observable<any> {
        return this.http.post(this.rootUrl + '/newpassword', params);
    }

    public getCurrentUser(): Observable<any> {
        return this.http.get(this.rootUrl + '/user');
    }
}
