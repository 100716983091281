import { createSelector } from 'reselect';
import { AppState } from '../state.interface';

import * as reducer from './banner.reducer';

export const getState = (state: AppState) => state.banner;

export const addBannerLoading = createSelector(getState, reducer.addBannerLoading);
export const addBannerLoaded = createSelector(getState, reducer.addBannerLoaded);
export const addBannerFail = createSelector(getState, reducer.addBannerFail);
export const addBannerData = createSelector(getState, reducer.addBannerData);
export const addBannerErrMes = createSelector(getState, reducer.addBannerErrMes);

export const updateBannerLoading = createSelector(getState, reducer.updateBannerLoading);
export const updateBannerLoaded = createSelector(getState, reducer.updateBannerLoaded);
export const updateBannerFail = createSelector(getState, reducer.updateBannerFail);
export const updateBannerData = createSelector(getState, reducer.updateBannerData);
export const updateBannerErrMes = createSelector(getState, reducer.updateBannerErrMes);

export const getBannerByIdLoading = createSelector(getState, reducer.getBannerByIdLoading);
export const getBannerByIdLoaded = createSelector(getState, reducer.getBannerByIdLoaded);
export const getBannerByIdFail = createSelector(getState, reducer.getBannerByIdFail);
export const getBannerByIdData = createSelector(getState, reducer.getBannerByIdData);

export const getBannerListLoading = createSelector(getState, reducer.getBannerListLoading);
export const getBannerListLoaded = createSelector(getState, reducer.getBannerListLoaded);
export const getBannerListFail = createSelector(getState, reducer.getBannerListFail);
export const getBannerListData = createSelector(getState, reducer.getBannerListData);

export const deleteBannerItemLoading = createSelector(getState, reducer.deleteBannerItemLoading);
export const deleteBannerItemLoaded = createSelector(getState, reducer.deleteBannerItemLoaded);
export const deleteBannerItemFail = createSelector(getState, reducer.deleteBannerItemFail);

export const addBannerSliderLoading = createSelector(getState, reducer.addBannerSliderLoading);
export const addBannerSliderLoaded = createSelector(getState, reducer.addBannerSliderLoaded);
export const addBannerSliderFail = createSelector(getState, reducer.addBannerSliderFail);
export const addBannerSliderData = createSelector(getState, reducer.addBannerSliderData);
export const addBannerSliderErrMess = createSelector(getState, reducer.addBannerSliderErrMess);

export const deleteBannerSliderLoading = createSelector(getState, reducer.deleteBannerSliderLoading);
export const deleteBannerSliderLoaded = createSelector(getState, reducer.deleteBannerSliderLoaded);
export const deleteBannerSliderFail = createSelector(getState, reducer.deleteBannerSliderFail);

export const getBannerSliderListLoading = createSelector(getState, reducer.getBannerSliderListLoading);
export const getBannerSliderListLoaded = createSelector(getState, reducer.getBannerSliderListLoaded);
export const getBannerSliderListFail = createSelector(getState, reducer.getBannerSliderListFail);
export const getBannerSliderListData = createSelector(getState, reducer.getBannerSliderListData);

export const updateBannerSliderLoading = createSelector(getState, reducer.updateBannerSliderLoading);
export const updateBannerSliderLoaded = createSelector(getState, reducer.updateBannerSliderLoaded);
export const updateBannerSliderFail = createSelector(getState, reducer.updateBannerSliderFail);
export const updateBannerSliderData = createSelector(getState, reducer.updateBannerSliderData);

export const getBannerSliderItemLoading = createSelector(getState, reducer.getBannerSliderItemLoading);
export const getBannerSliderItemLoaded = createSelector(getState, reducer.getBannerSliderItemLoaded);
export const getBannerSliderItemFail = createSelector(getState, reducer.getBannerSliderItemFail);
export const getBannerSliderItemData = createSelector(getState, reducer.getBannerSliderItemData);

