<h1 mat-dialog-title>{{'Add video' | translate}}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill" style="width: 360px;">
        <mat-label>{{'Video url' | translate}}:</mat-label>
        <input matInput [formControl]="url">
        <!-- <mat-hint>https://www.youtube.com/</mat-hint> -->
        <mat-error *ngIf="url.invalid">
            {{'Please enter a valid url address' | translate}}
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="submit()" [disabled]="url.invalid">
        {{'Submit' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="null" cdkFocusInitial>
        {{'Close' | translate}}
    </button>
</div>