import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserModel } from "app/core/account/models/registerUser.model";
import { ClusterModel } from "app/core/root/models/cluster.model";
import { RootSandbox } from "app/core/root/root.sandbox";
// import { ACCOUNT_VERSION, USER_ROLES } from "app/core/shared/helpers/constants.enum";
import { Subscription } from "rxjs";
import { SnackService } from "../snack.service";
import { ShopSandbox } from '../../core/shop/shop.sandbox';
import { ShopSettingsModel } from '../../core/shop/models/shop-settings.model';

@Component({
    templateUrl: './settings-shop.dialog.html',
    styleUrls: ['./settings-shop.dialog.scss'],
})
export class SettingsShopDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    private subs: Subscription[] = [];

    form: FormGroup;
    url: FormControl = new FormControl('', Validators.required);
    urlApi: FormControl = new FormControl('', Validators.required);
    ws: FormControl = new FormControl('', Validators.required);
    appleId: FormControl = new FormControl('');
    appleBundleId: FormControl = new FormControl('');
    androidId: FormControl = new FormControl('');
    oneSignalId: FormControl = new FormControl('');

    settingsData: ShopSettingsModel;

    constructor(
        private dialogRef: MatDialogRef<SettingsShopDialogComponent>,
        private fb: FormBuilder,
        public rootSandbox: RootSandbox,
        private cd: ChangeDetectorRef,
        private message: SnackService,
        private shopSandbox: ShopSandbox,
        @Inject(MAT_DIALOG_DATA) public data: UserModel,
    ) { }

    ngOnInit(): void {
      console.log(this.data);
        this.form = this.fb.group({
            url: this.url,
            urlApi: this.urlApi,
            ws: this.ws,
            appleId: this.appleId,
            appleBundleId: this.appleBundleId,
            androidId: this.androidId,
            oneSignalId: this.oneSignalId
        });
        this.shopSandbox.doClearShopSettingsState();
          this.shopSandbox.doGetShopSettings({
            email: this.data.email
        });
    }
    
    ngAfterViewInit(): void {
      this.subs.push(this.shopSandbox.getShopSettingsData$.subscribe(data => {
        console.log(data);
        if (!!data) {
          this.settingsData = data;
          this.url.patchValue(this.settingsData.linkshop)
          this.urlApi.patchValue(this.settingsData.websevicedata.linkapi)
          this.ws.patchValue(this.settingsData.websevicedata.allWsKey)
          this.appleId.patchValue(this.settingsData.appsettings.applePrefixId);
          this.appleBundleId.patchValue(this.settingsData.appsettings.appleBundleId);
          this.androidId.patchValue(this.settingsData.appsettings.androidId);
          this.oneSignalId.patchValue(this.settingsData.websevicedata.onesignalAppId);
        } 
     }));

        // this.rootSandbox.doGetClustersList({});
        // this.rootSandbox.doClearUpdateUserState();
        // this.rootSandbox.doClearCreateUser();

        // if (this.data) {
        //     this.email.patchValue(this.data.email);
        //     this.name.patchValue(this.data.name);

        //     this.clusterId.patchValue(this.data.cluster_id);
        //     this.role.patchValue(this.data.role);

        //     this.plan.patchValue(this.data.plan);
        //     // console.log(this.data);
        // }

        // this.subs.push(this.rootSandbox.getClustersListData$.subscribe((res: ClusterModel[]) => {
        //     if (res) {
        //         this.clustersList = res.filter(el => el.active);
        //         // console.log(this.clustersList);
        //     } else {
        //         this.clustersList = [];
        //     }
        //     this.cd.markForCheck();
        // }));
        // this.subs.push(this.rootSandbox.createUserData$.subscribe(res => {
        //     if (res) {
        //         // console.log(res);
        //         this.dialogRef.close(res);
        //     }
        // }));
        // this.subs.push(this.rootSandbox.createUserErrMess$.subscribe(res => {
        //     if (res) {
        //         this.message.log(res);
        //     }
        // }));
        // this.subs.push(this.rootSandbox.updateUserData$.subscribe(res => {
        //     if (res) {
        //         // console.log(res);
        //         this.dialogRef.close(res);
        //     }
        // }));
    }

    

    confirm(): void {
      console.log('confirm')
      if (this.form.valid) {
            const params: any = {
                allWsKey: this.ws.value,
                androidId: this.androidId.value,
                appleBundleId: this.appleBundleId.value,
                applePrefixId: this.appleId.value,
                linkShop: this.url.value,
                linkApi: this.urlApi.value,
                onesignalAppId: this.oneSignalId.value,
                idShopname: this.data.id_shopname
            };
            // console.log(params)

            if (!!this.data) {
              params.email = this.data.email;
            }


            console.log("params -> ", params);
            console.log("data -> ", this.data);
            this.shopSandbox.doAddShop(params);
            this.shopSandbox.addShopLoaded$.subscribe(data => {
              if (!!data) {
                this.message.log('Updated successfuly!')
              }
            })
            this.shopSandbox.addShopFail$.subscribe(err => {
              if (!!err) {
                this.message.log('Error!')
              }
            })
        } else {
            this.message.log('Form not valid!');
        }
        // if (this.form.valid) {
        //     const params: any = {
        //         email: this.email.value,
        //         name: this.name.value,
        //         role: this.role.value,
        //         cluster_id: this.clusterId.value,
        //         plan: this.plan.value
        //     }
        //     if (this.password.value) {
        //         params.password = this.password.value;
        //     }

        //     if (this.data) {
        //         params.id = this.data.id;
        //         this.rootSandbox.doUpdateUser(params);
        //     } else {
        //         this.rootSandbox.doCreateUser(params);
        //     }
        // } else {
        //     this.message.log('Invalid form');
        // }
        // // this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}


