import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ONESIGNAL_LAN } from 'app/core/shared/helpers/onesignal.lang';
import { LanguageModel } from 'app/core/shop/models/language.model';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './translation-field.dialog.html',
    styleUrls: ['./translation-field.dialog.scss']
})
export class TranslationSelectionDialogComponent implements OnInit, OnDestroy {
    ONESIGNAL_LAN = ONESIGNAL_LAN;
    private subs: Subscription[] = [];

    public idShopname: string = null;
    public langList: LanguageModel[] = [];

    form: FormGroup;
    text: FormControl = new FormControl('', Validators.required);
    lang: FormControl = new FormControl('', Validators.required);

    constructor(
        private dialogRef: MatDialogRef<TranslationSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            lngKey: string;
            value: string;
        },
        private dialog: MatDialog,
        private shopSandbox: ShopSandbox,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder
    ) {
        if (this.data && this.data.lngKey) {
            this.text.patchValue(this.data.value || '');
            this.lang.disable();
        }
    }

    ngOnInit(): void {
        this.shopSandbox.doClearGetShopLangListState();

        this.form = this.fb.group({
            text: this.text,
            lang: this.lang
        });

        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.idShopname = shopName;
            // this.getLangList();
        }));
        // this.subs.push(this.shopSandbox.getShopLangListData$.subscribe(res => {
        //     this.langList = res || [];
        //     this.pathEditedValue();
        //     this.cd.markForCheck();
        // }));

        this.langList = Object.keys(ONESIGNAL_LAN).map(key => {
            return new LanguageModel({ id: null, isocode: key, name: ONESIGNAL_LAN[key] });
        });

        if (this.data && this.data.lngKey) {
            const curr = this.langList.find(el => el.isocode === this.data.lngKey);
            if (curr) {
                this.lang.patchValue(curr);
            }
        }
    }

    // private pathEditedValue(): void {
    //     if (this.data && this.data.lngKey) {
    //         const curr = this.langList.find(el => el.isocode === this.data.lngKey);
    //         if (curr) {
    //             this.lang.patchValue(curr);
    //         }
    //     }
    // }

    // private getLangList(): void {
    //     if (!this.idShopname) {
    //         return;
    //     }
    //     const params: any = {
    //         idShopname: this.idShopname
    //     };
    //     this.shopSandbox.doGetShopLangList(params);
    // }

    submit(): void {
        if (this.form.invalid) {
            return;
        }
        this.dialogRef.close({
            text: this.text.value,
            lang: this.lang.value
        });
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}