<h1 mat-dialog-title>{{'Select product' | translate}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'Search query' | translate}}</mat-label>
        <input matInput placeholder="Ex. Pizza" [formControl]="myControl">
        <mat-icon class="icon-size-5" matPrefix>notes</mat-icon>
    </mat-form-field>

    <mat-selection-list #shoes [multiple]="false">
        <mat-list-option *ngFor="let product of productsList" [value]="product" (click)="select(product)">
            <ng-container *ngIf="product.title">
                #{{product.id}} - {{product.title | multiLang}}
            </ng-container>
        </mat-list-option>
    </mat-selection-list>
    <mat-progress-bar mode="indeterminate" *ngIf="commonSandbox.getProductsListLoading$ | async">
    </mat-progress-bar>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Close' | translate}}
    </button>
</div>