import { createSelector } from 'reselect';
import { AppState } from '../state.interface';

import * as reducer from './shop.reducer';
import { ShopState } from './shop.state';

export const getState = (state: AppState) => state.shop;

export const addShopLoading = createSelector(getState, reducer.addShopLoading);
export const addShopLoaded = createSelector(getState, reducer.addShopLoaded);
export const addShopFail = createSelector(getState, reducer.addShopFail);
export const addShopData = createSelector(getState, reducer.addShopData);

export const getShopSettingsLoading = createSelector(getState, reducer.getShopSettingsLoading);
export const getShopSettingsLoaded = createSelector(getState, reducer.getShopSettingsLoaded);
export const getShopSettingsFail = createSelector(getState, reducer.getShopSettingsFail);
export const getShopSettingsData = createSelector(getState, reducer.getShopSettingsData);
export const shopSettingsErrors = createSelector(getState, (state: ShopState) => state.shopSettingsErrors);

export const currentShopName = createSelector(getState, reducer.currentShopName);

export const getMenuItemLoading = createSelector(getState, reducer.getMenuItemLoading);
export const getMenuItemLoaded = createSelector(getState, reducer.getMenuItemLoaded);
export const getMenuItemFail = createSelector(getState, reducer.getMenuItemFail);
export const getMenuItemData = createSelector(getState, reducer.getMenuItemData);

export const updateMenuItemLoading = createSelector(getState, reducer.updateMenuItemLoading);
export const updateMenuItemLoaded = createSelector(getState, reducer.updateMenuItemLoaded);
export const updateMenuItemFail = createSelector(getState, reducer.updateMenuItemFail);
export const updateMenuItemData = createSelector(getState, reducer.updateMenuItemData);

export const getOrderStatusListLoading = createSelector(getState, reducer.getOrderStatusListLoading);
export const getOrderStatusListLoaded = createSelector(getState, reducer.getOrderStatusListLoaded);
export const getOrderStatusListFail = createSelector(getState, reducer.getOrderStatusListFail);
export const getOrderStatusListData = createSelector(getState, reducer.getOrderStatusListData);

export const getShopLangListLoading = createSelector(getState, reducer.getShopLangListLoading);
export const getShopLangListLoaded = createSelector(getState, reducer.getShopLangListLoaded);
export const getShopLangListFail = createSelector(getState, reducer.getShopLangListFail);
export const getShopLangListData = createSelector(getState, reducer.getShopLangListData);

export const getBrandsListLoading = createSelector(getState, reducer.getBrandsListLoading);
export const getBrandsListLoaded = createSelector(getState, reducer.getBrandsListLoaded);
export const getBrandsListFail = createSelector(getState, reducer.getBrandsListFail);
export const getBrandsListData = createSelector(getState, reducer.getBrandsListData);

export const createPaypalHookLoading = createSelector(getState, reducer.createPaypalHookLoading);
export const createPaypalHookLoaded = createSelector(getState, reducer.createPaypalHookLoaded);
export const createPaypalHookFail = createSelector(getState, reducer.createPaypalHookFail);
export const createPaypalHookData = createSelector(getState, reducer.createPaypalHookData);

export const createStripeHookLoading = createSelector(getState, reducer.createStripeHookLoading);
export const createStripeHookLoaded = createSelector(getState, reducer.createStripeHookLoaded);
export const createStripeHookFail = createSelector(getState, reducer.createStripeHookFail);
export const createStripeHookData = createSelector(getState, reducer.createStripeHookData);

export const getShopErrorListLoading = createSelector(getState, reducer.getShopErrorListLoading);
export const getShopErrorListLoaded = createSelector(getState, reducer.getShopErrorListLoaded);
export const getShopErrorListFail = createSelector(getState, reducer.getShopErrorListFail);
export const getShopErrorListData = createSelector(getState, reducer.getShopErrorListData);

export const updateErrorStatusLoading = createSelector(getState, reducer.updateErrorStatusLoading);
export const updateErrorStatusLoaded = createSelector(getState, reducer.updateErrorStatusLoaded);
export const updateErrorStatusFail = createSelector(getState, reducer.updateErrorStatusFail);
export const updateErrorStatusData = createSelector(getState, reducer.updateErrorStatusData);

export const getCurrencyListData = createSelector(getState, (state: ShopState) => state.getCurrencyListData);
export const getCurrencyListLoading = createSelector(getState, (state: ShopState) => state.getCurrencyListLoading);
export const getCurrencyListFail = createSelector(getState, (state: ShopState) => state.getCurrencyListFail);
