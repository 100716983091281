import { Action } from '@ngrx/store';
import { type } from '../shared/utility/utilityHelper';

export const ActionTypes = {
    REGISTER_USER: type('REGISTER_USER'),
    REGISTER_USER_SUCCESS: type('REGISTER_USER_SUCCESS'),
    REGISTER_USER_FAIL: type('REGISTER_USER_FAIL'),
    CLEAR_REGISTER_STATE: type('CLEAR_REGISTER_STATE'),

    LOGIN_USER: type('LOGIN_USER'),
    LOGIN_USER_SUCCESS: type('LOGIN_USER_SUCCESS'),
    LOGIN_USER_FAIL: type('LOGIN_USER_FAIL'),
    CLEAR_LOGIN_STATE: type('CLEAR_LOGIN_STATE'),

    SEND_PASSWORD_RECOVERY_KEY: type('SEND_PASSWORD_RECOVERY_KEY'),
    SEND_PASSWORD_RECOVERY_KEY_SUCCESS: type('SEND_PASSWORD_RECOVERY_KEY_SUCCESS'),
    SEND_PASSWORD_RECOVERY_KEY_FAIL: type('SEND_PASSWORD_RECOVERY_KEY_FAIL'),
    CLEAR_SEND_PASSWORD_RECOVERY_KEY_STATE: type('CLEAR_SEND_PASSWORD_RECOVERY_KEY_STATE'),

    CHANGE_PASSWORD: type('CHANGE_PASSWORD'),
    CHANGE_PASSWORD_SUCCESS: type('CHANGE_PASSWORD_SUCCESS'),
    CHANGE_PASSWORD_FAIL: type('CHANGE_PASSWORD_FAIL'),
    CLEAR_CHANGE_PASSWORD_STATE: type('CLEAR_CHANGE_PASSWORD_STATE'),

    DO_LOGOUT: type('DO_LOGOUT'),
    DO_LOGOUT_SUCCESS: type('DO_LOGOUT_SUCCESS'),

    GET_CURRENT_USER: type('GET_CURRENT_USER'),
    GET_CURRENT_USER_FAIL: type('GET_CURRENT_USER_FAIL'),
    GET_CURRENT_USER_SUCCESS: type('GET_CURRENT_USER_SUCCESS'),
    CLEAR_GET_CURRENT_USER_STATE: type('CLEAR_GET_CURRENT_USER_STATE'),
};

export class RegisterUserAction implements Action {
    type = ActionTypes.REGISTER_USER;
    constructor(public payload: any) { }
}
export class RegisterUserFailAction implements Action {
    type = ActionTypes.REGISTER_USER_FAIL;
    constructor(public payload: any) { }
}
export class RegisterUserSuccessAction implements Action {
    type = ActionTypes.REGISTER_USER_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearRegisterStateAction implements Action {
    type = ActionTypes.CLEAR_REGISTER_STATE;
    constructor(public payload: any) { }
}

export class LoginUserAction implements Action {
    type = ActionTypes.LOGIN_USER;
    constructor(public payload: any) { }
}
export class LoginUserSuccessAction implements Action {
    type = ActionTypes.LOGIN_USER_SUCCESS;
    constructor(public payload: any) { }
}
export class LoginUserFailAction implements Action {
    type = ActionTypes.LOGIN_USER_FAIL;
    constructor(public payload: any) { }
}
export class ClearLoginStateAction implements Action {
    type = ActionTypes.CLEAR_LOGIN_STATE;
    constructor(public payload: any) { }
}

export class SendPasswordRecoveryKeyAction implements Action {
    type = ActionTypes.SEND_PASSWORD_RECOVERY_KEY;
    constructor(public payload: any) { }
}
export class SendPasswordRecoveryKeyFailAction implements Action {
    type = ActionTypes.SEND_PASSWORD_RECOVERY_KEY_FAIL;
    constructor(public payload: any) { }
}
export class SendPasswordRecoveryKeySuccessAction implements Action {
    type = ActionTypes.SEND_PASSWORD_RECOVERY_KEY_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearSendRecoveryPasswordKeyStateAction implements Action {
    type = ActionTypes.CLEAR_SEND_PASSWORD_RECOVERY_KEY_STATE;
    constructor(public payload: any) { }
}

export class ChangePasswordAction implements Action {
    type = ActionTypes.CHANGE_PASSWORD;
    constructor(public payload: any) { }
}
export class ChangePasswordFailAction implements Action {
    type = ActionTypes.CHANGE_PASSWORD_FAIL;
    constructor(public payload: any) { }
}
export class ChangePasswordSuccessAction implements Action {
    type = ActionTypes.CHANGE_PASSWORD_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearChangePasswordStateAction implements Action {
    type = ActionTypes.CLEAR_CHANGE_PASSWORD_STATE;
    constructor(public payload: any) { }
}

export class DoLogoutAction implements Action {
    type = ActionTypes.DO_LOGOUT;
    constructor(public payload: any) { }
}
export class DoLogoutSuccessAction implements Action {
    type = ActionTypes.DO_LOGOUT_SUCCESS;
    constructor(public payload: any) { }
}

export class GetCurrentUserAction implements Action {
    type = ActionTypes.GET_CURRENT_USER;
    constructor(public payload: any) { }
}
export class GetCurrentUserSuccessAction implements Action {
    type = ActionTypes.GET_CURRENT_USER_SUCCESS;
    constructor(public payload: any) { }
}
export class GetCurrentUserFailAction implements Action {
    type = ActionTypes.GET_CURRENT_USER_FAIL;
    constructor(public payload: any) { }
}
export class ClearGetCurrentUserStateAction implements Action {
    type = ActionTypes.CLEAR_GET_CURRENT_USER_STATE;
    constructor(public payload: any) { }
}

export type Actions =
    | RegisterUserAction
    | RegisterUserFailAction
    | RegisterUserSuccessAction
    | ClearRegisterStateAction
    | LoginUserAction
    | LoginUserFailAction
    | LoginUserSuccessAction
    | ClearLoginStateAction
    | SendPasswordRecoveryKeyAction
    | SendPasswordRecoveryKeyFailAction
    | SendPasswordRecoveryKeySuccessAction
    | ClearSendRecoveryPasswordKeyStateAction
    | ChangePasswordAction
    | ChangePasswordFailAction
    | ChangePasswordSuccessAction
    | ClearChangePasswordStateAction
    | DoLogoutAction
    | DoLogoutSuccessAction
    | GetCurrentUserAction
    | GetCurrentUserFailAction
    | GetCurrentUserSuccessAction
    | ClearGetCurrentUserStateAction;

