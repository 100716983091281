export class ShopSettingsModel {
    public appsettings: {
        androidId: string;
        appleBundleId: string;
        applePrefixId: string;
        currency: string;
        isocurrency: string;
        isolng: string;
        lng: string;
        symbolcurrency: string;
        paymentsList: string[];
        lngList: string[];
    };

    public associations: {
        img_links: any;
    };

    public dateAdd: string;
    public dateUpd: string;
    public email: string;
    public id: number;
    public idclient: string;
    public idshopname: string;
    public ip: string;
    public linkshop: string;
    public mobile: string;

    public paypaldata: PayPalSettingsModel;

    // {
    //     clientId: string;
    //     secret: string;
    //     paymentState: number;
    // };

    public prestashopconfigurations: any;
    public prestashopsettings: any;

    public stripedata: StripeSettingsModel;
    // {
    //     secretKey: string;
    //     paymentState: number;
    // };

    public token;
    public websevicedata: {
        addressWsKey: string;
        allWsKey: string;
        carrierWsKey: string;
        cartWsKey: string;
        categoryWsKey: string;
        cookieKey: string;
        customerWsKey: string;
        guestWsKey: string;
        imageWsKey: string;
        languageWsKey: string;
        linkapi: string;
        manufacturerWsKey: string;
        onesignalAppId: string;
        orderWsKey: string;
        productWsKey: string;
    };

    enableCoupons: boolean;
    hideProductZeroPrice: boolean;
    activeCheckout: boolean;

    constructor(res: any) {
        this.appsettings = res.appsettings || {};
        this.associations = res.associations || {};
        this.dateAdd = res.date_add || '';
        this.dateUpd = res.date_upd || '';
        this.email = res.email;
        this.id = res.id;
        this.idclient = res.idclient;
        this.idshopname = res.idshopname;
        this.ip = res.ip;
        this.linkshop = res.linkshop;
        this.mobile = res.mobile;
        this.paypaldata = new PayPalSettingsModel(res.paypaldata || {});
        this.prestashopconfigurations = res.prestashopconfigurations || {};
        this.prestashopsettings = res.prestashopsettings || {};
        this.stripedata = new StripeSettingsModel(res.stripedata || {});
        this.token = res.token;
        this.websevicedata = res.websevicedata || {};

        this.enableCoupons = res?.appsettings?.enableCoupons === '1' ? true : false;
        this.hideProductZeroPrice = res?.appsettings?.hideProductZeroPrice === '1' ? true : false;
        this.activeCheckout = res?.appsettings?.activeCheckout === '1' ? true : false;
    }
}

export class PayPalSettingsModel {
    public live: {
        clientId: string;
        secret: string;
        paymentState: string;
        orderState: string;
    }
    public sandbox: {
        clientId: string;
        secret: string;
        paymentState: string;
        orderState: string;
    }
    constructor(res: any) {
        const nullItem = {
            clientId: '',
            secret: '',
            paymentState: 0
        }

        this.live = res.live ? res.live : { ...nullItem };
        this.sandbox = res.sandbox ? res.sandbox : { ...nullItem };
    }
}

export class StripeSettingsModel {
    public live: {
        secretKey: string;
        publicKey: string;
        paymentState: string;
        orderState: string;
    }
    public test: {
        secretKey: string;
        publicKey: string;
        paymentState: string;
        orderState: string;
    }
    constructor(res: any) {
        this.live = res?.live ? res.live : {secretKey: ''};
        this.test = res?.test ? res.test : {secretKey: ''};
    }
}
