import { BannerRecord, BannerState } from './banner.state';
import * as actions from './banner.action';
import { BannerCreateModel } from './models/banner-create.model';
import { BannerModel } from './models/banner-item.model';
import { SliderModel } from './models/slider.model';

export const initialState: BannerState = (new BannerRecord() as unknown) as BannerState;

export function reducer(state = initialState, { type, payload }: any): BannerState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.ADD_BANNER: {
            return Object.assign({}, state, {
                addBannerLoading: true,
                addBannerLoaded: false,
                addBannerFail: false,
                addBannerData: {},
                addBannerErrMes: {},
            });
        }
        case actions.ActionTypes.ADD_BANNER_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                addBannerLoading: false,
                addBannerLoaded: true,
                addBannerFail: false,
                addBannerData: new BannerCreateModel(payload),
                addBannerErrMes: null,
            });
        }
        case actions.ActionTypes.ADD_BANNER_FAIL: {
            return Object.assign({}, state, {
                addBannerLoading: false,
                addBannerLoaded: false,
                addBannerFail: true,
                addBannerData: {},
                addBannerErrMes: {},
            });
        }
        case actions.ActionTypes.CLEAR_ADD_BANNER_STATE: {
            return Object.assign({}, state, {
                addBannerLoading: false,
                addBannerLoaded: false,
                addBannerFail: false,
                addBannerData: null,
                addBannerErrMes: null,
            });
        }

        case actions.ActionTypes.UPDATE_BANNER: {
            return Object.assign({}, state, {
                updateBannerLoading: true,
                updateBannerLoaded: false,
                updateBannerFail: false,
                updateBannerData: null,
                updateBannerErrMes: null,
            });
        }
        case actions.ActionTypes.UPDATE_BANNER_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                updateBannerLoading: false,
                updateBannerLoaded: true,
                updateBannerFail: false,
                updateBannerData: payload.data && payload.data[0] ? new BannerModel(payload.data[0]) : null,
                updateBannerErrMes: payload.codeMsg && !payload.success ? payload.codeMsg : null,
            });
        }
        case actions.ActionTypes.UPDATE_BANNER_FAIL: {
            return Object.assign({}, state, {
                updateBannerLoading: false,
                updateBannerLoaded: false,
                updateBannerFail: true,
                updateBannerData: null,
                updateBannerErrMes: payload.codeMsg && !payload.success ? payload.codeMsg : null,
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_BANNER_ITEM_STATE: {
            return Object.assign({}, state, {
                updateBannerLoading: false,
                updateBannerLoaded: false,
                updateBannerFail: false,
                updateBannerData: null,
                updateBannerErrMes: null,
            });
        }

        case actions.ActionTypes.GET_BANNER_ITEM: {
            return Object.assign({}, state, {
                getBannerByIdLoading: true,
                getBannerByIdLoaded: false,
                getBannerByIdFail: false,
                getBannerByIdData: {},
            });
        }
        case actions.ActionTypes.GET_BANNER_ITEM_SUCCES: {
            let baner = null;
            if (payload && payload.data && !!payload.data[0]) {
                baner = new BannerModel(payload.data[0]);
            }
            // console.log(payload);
            return Object.assign({}, state, {
                getBannerByIdLoading: false,
                getBannerByIdLoaded: true,
                getBannerByIdFail: false,
                getBannerByIdData: baner,
            });
        }
        case actions.ActionTypes.GET_BANNER_ITEM_FAIL: {
            return Object.assign({}, state, {
                getBannerByIdLoading: false,
                getBannerByIdLoaded: false,
                getBannerByIdFail: true,
                getBannerByIdData: {},
            });
        }

        case actions.ActionTypes.DELETE_BANNER_ITEM: {
            return Object.assign({}, state, {
                deleteBannerItemLoading: true,
                deleteBannerItemLoaded: false,
                deleteBannerItemFail: false,
            });
        }
        case actions.ActionTypes.DELETE_BANNER_ITEM_SUCCESS: {
            return Object.assign({}, state, {
                deleteBannerItemLoading: false,
                deleteBannerItemLoaded: true,
                deleteBannerItemFail: false,
            });
        }
        case actions.ActionTypes.DELETE_BANNER_ITEM_FAIL: {
            return Object.assign({}, state, {
                deleteBannerItemLoading: false,
                deleteBannerItemLoaded: false,
                deleteBannerItemFail: true,
            });
        }
        case actions.ActionTypes.CLEAR_DELETE_BANNER_ITEM_STATE: {
            return Object.assign({}, state, {
                deleteBannerItemLoading: false,
                deleteBannerItemLoaded: false,
                deleteBannerItemFail: false,
            });
        }

        case actions.ActionTypes.GET_BANNER_LIST: {
            return Object.assign({}, state, {
                getBannerListLoading: true,
                getBannerListLoaded: false,
                getBannerListFail: false,
                getBannerListData: null,
            });
        }
        case actions.ActionTypes.GET_BANNER_LIST_SUCCESS: {
            let banenrs = [];
            if (payload && payload.data && !!payload.data.length) {
                banenrs = payload.data.map((el: any) => new BannerModel(el));
            }
            // console.log(payload);
            return Object.assign({}, state, {
                getBannerListLoading: false,
                getBannerListLoaded: true,
                getBannerListFail: false,
                getBannerListData: banenrs,
            });
        }
        case actions.ActionTypes.GET_BANNER_LIST_FAIL: {
            return Object.assign({}, state, {
                getBannerListLoading: false,
                getBannerListLoaded: false,
                getBannerListFail: true,
                getBannerListData: null,
            });
        }

        case actions.ActionTypes.ADD_BANNER_SLIDER: {
            return Object.assign({}, state, {
                addBannerSliderLoading: true,
                addBannerSliderLoaded: false,
                addBannerSliderFail: false,
                addBannerSliderData: null,
                addBannerSliderErrMess: null,
            });
        }
        case actions.ActionTypes.ADD_BANNER_SLIDER_SUCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                addBannerSliderLoading: false,
                addBannerSliderLoaded: true,
                addBannerSliderFail: false,
                addBannerSliderData: payload && payload.data ? new SliderModel(payload.data) : null,
                addBannerSliderErrMess: payload.isError && payload.message ? payload.message : null
            });
        }
        case actions.ActionTypes.ADD_BANNER_SLIDER_FAIL: {
            return Object.assign({}, state, {
                addBannerSliderLoading: false,
                addBannerSliderLoaded: false,
                addBannerSliderFail: true,
                addBannerSliderData: null,
                addBannerSliderErrMess: payload.isError && payload.message ? payload.message : null
            });
        }
        case actions.ActionTypes.CLEAR_ADD_BANNER_SLIDER_STATE: {
            return Object.assign({}, state, {
                addBannerSliderLoading: false,
                addBannerSliderLoaded: false,
                addBannerSliderFail: false,
                addBannerSliderData: null,
                addBannerSliderErrMess: null,
            });
        }

        case actions.ActionTypes.DELETE_BANNER_SLIDER: {
            return Object.assign({}, state, {
                deleteBannerSliderLoading: true,
                deleteBannerSliderLoaded: false,
                deleteBannerSliderFail: false,
            });
        }
        case actions.ActionTypes.DELETE_BANNER_SLIDER_SUCCESS: {
            return Object.assign({}, state, {
                deleteBannerSliderLoading: false,
                deleteBannerSliderLoaded: true,
                deleteBannerSliderFail: false,
            });
        }
        case actions.ActionTypes.DELETE_BANNER_SLIDER_FAIL: {
            return Object.assign({}, state, {
                deleteBannerSliderLoading: false,
                deleteBannerSliderLoaded: false,
                deleteBannerSliderFail: true,
            });
        }

        case actions.ActionTypes.GET_BANNER_SLIDER_LIST: {
            return Object.assign({}, state, {
                getBannerSliderListLoading: true,
                getBannerSliderListLoaded: false,
                getBannerSliderListFail: false,
                getBannerSliderListData: null,
            });
        }
        case actions.ActionTypes.GET_BANNER_SLIDER_LIST_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                getBannerSliderListLoading: false,
                getBannerSliderListLoaded: true,
                getBannerSliderListFail: false,
                getBannerSliderListData: payload,
            });
        }
        case actions.ActionTypes.GET_BANNER_SLIDER_LIST_FAIL: {
            return Object.assign({}, state, {
                getBannerSliderListLoading: false,
                getBannerSliderListLoaded: false,
                getBannerSliderListFail: true,
                getBannerSliderListData: null,
            });
        }

        case actions.ActionTypes.UPDATE_BANNER_SLIDER_ITEM: {
            return Object.assign({}, state, {
                updateBannerSliderLoading: true,
                updateBannerSliderLoaded: false,
                updateBannerSliderFail: false,
                updateBannerSliderData: null,
            });
        }
        case actions.ActionTypes.UPDATE_BANNER_SLIDER_ITEM_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                updateBannerSliderLoading: false,
                updateBannerSliderLoaded: true,
                updateBannerSliderFail: false,
                updateBannerSliderData: payload,
            });
        }
        case actions.ActionTypes.UPDATE_BANNER_SLIDER_ITEM_FAIL: {
            return Object.assign({}, state, {
                updateBannerSliderLoading: false,
                updateBannerSliderLoaded: false,
                updateBannerSliderFail: true,
                updateBannerSliderData: null,
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_BANNER_SLIDET_ITEM_STATE: {
            return Object.assign({}, state, {
                updateBannerSliderLoading: false,
                updateBannerSliderLoaded: false,
                updateBannerSliderFail: false,
                updateBannerSliderData: null,
            });
        }

        case actions.ActionTypes.GET_BANNER_SLIDER_ITEM: {
            return Object.assign({}, state, {
                getBannerSliderItemLoading: true,
                getBannerSliderItemLoaded: false,
                getBannerSliderItemFail: false,
                getBannerSliderItemData: null,
            });
        }
        case actions.ActionTypes.GET_BANNER_SLIDER_ITEM_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                getBannerSliderItemLoading: false,
                getBannerSliderItemLoaded: true,
                getBannerSliderItemFail: false,
                getBannerSliderItemData: payload && payload.data && payload.data[0] ? new SliderModel(payload.data[0]) : null,
            });
        }
        case actions.ActionTypes.GET_BANNER_SLIDER_ITEM_FAIL: {
            return Object.assign({}, state, {
                getBannerSliderItemLoading: false,
                getBannerSliderItemLoaded: false,
                getBannerSliderItemFail: true,
                getBannerSliderItemData: null,
            });
        }

        default:
            return state;
    }
}

export const addBannerLoading = (state: BannerState) => state.addBannerLoading;
export const addBannerLoaded = (state: BannerState) => state.addBannerLoaded;
export const addBannerFail = (state: BannerState) => state.addBannerFail;
export const addBannerData = (state: BannerState) => state.addBannerData;
export const addBannerErrMes = (state: BannerState) => state.addBannerErrMes;

export const updateBannerLoading = (state: BannerState) => state.updateBannerLoading;
export const updateBannerLoaded = (state: BannerState) => state.updateBannerLoaded;
export const updateBannerFail = (state: BannerState) => state.updateBannerFail;
export const updateBannerData = (state: BannerState) => state.updateBannerData;
export const updateBannerErrMes = (state: BannerState) => state.updateBannerErrMes;

export const getBannerByIdLoading = (state: BannerState) => state.getBannerByIdLoading;
export const getBannerByIdLoaded = (state: BannerState) => state.getBannerByIdLoaded;
export const getBannerByIdFail = (state: BannerState) => state.getBannerByIdFail;
export const getBannerByIdData = (state: BannerState) => state.getBannerByIdData;

export const getBannerListLoading = (state: BannerState) => state.getBannerListLoading;
export const getBannerListLoaded = (state: BannerState) => state.getBannerListLoaded;
export const getBannerListFail = (state: BannerState) => state.getBannerListFail;
export const getBannerListData = (state: BannerState) => state.getBannerListData;

export const deleteBannerItemLoading = (state: BannerState) => state.deleteBannerItemLoading;
export const deleteBannerItemLoaded = (state: BannerState) => state.deleteBannerItemLoaded;
export const deleteBannerItemFail = (state: BannerState) => state.deleteBannerItemFail;

export const addBannerSliderLoading = (state: BannerState) => state.addBannerSliderLoading;
export const addBannerSliderLoaded = (state: BannerState) => state.addBannerSliderLoaded;
export const addBannerSliderFail = (state: BannerState) => state.addBannerSliderFail;
export const addBannerSliderData = (state: BannerState) => state.addBannerSliderData;
export const addBannerSliderErrMess = (state: BannerState) => state.addBannerSliderErrMess;

export const deleteBannerSliderLoading = (state: BannerState) => state.deleteBannerSliderLoading;
export const deleteBannerSliderLoaded = (state: BannerState) => state.deleteBannerSliderLoaded;
export const deleteBannerSliderFail = (state: BannerState) => state.deleteBannerSliderFail;

export const getBannerSliderListLoading = (state: BannerState) => state.getBannerSliderListLoading;
export const getBannerSliderListLoaded = (state: BannerState) => state.getBannerSliderListLoaded;
export const getBannerSliderListFail = (state: BannerState) => state.getBannerSliderListFail;
export const getBannerSliderListData = (state: BannerState) => state.getBannerSliderListData;

export const updateBannerSliderLoading = (state: BannerState) => state.updateBannerSliderLoading;
export const updateBannerSliderLoaded = (state: BannerState) => state.updateBannerSliderLoaded;
export const updateBannerSliderFail = (state: BannerState) => state.updateBannerSliderFail;
export const updateBannerSliderData = (state: BannerState) => state.updateBannerSliderData;

export const getBannerSliderItemLoading = (state: BannerState) => state.getBannerSliderItemLoading;
export const getBannerSliderItemLoaded = (state: BannerState) => state.getBannerSliderItemLoaded;
export const getBannerSliderItemFail = (state: BannerState) => state.getBannerSliderItemFail;
export const getBannerSliderItemData = (state: BannerState) => state.getBannerSliderItemData;



