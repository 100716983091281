import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from 'app/core/navigation/navigation.service';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs = [
        {
            id: 'en',
            label: 'English'
        },
        {
            id: 'es',
            label: 'Spanish'
        }
    ];
    activeLang: string;
    flagCodes = {
        'en': 'en',
        'es': 'es'
    };;

    constructor(
        private cd: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,

        private navService: NavigationService
    ) {
        this.activeLang = this.translate.currentLang;
        this.document.documentElement.lang = this.translate.currentLang;
    }

    ngOnInit(): void {

        // Get the available languages from transloco
        // this.availableLangs = this._translocoService.getAvailableLangs();
        // console.log(this.availableLangs)

        // // Subscribe to language changes
        // this._translocoService.langChanges$.subscribe((activeLang) => {

        //     // Get the active lang
        //     this.activeLang = activeLang;

        //     // Update the navigation
        //     this._updateNavigation(activeLang);
        // });

        this.translate.onLangChange.subscribe((lan: LangChangeEvent) => {
            if (lan && lan.lang) {
                this.activeLang = lan.lang;
                this.document.documentElement.lang = lan.lang;

                // this._updateNavigation(this.activeLang);
                this.navService.get();

                const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
                if (navComponent) {
                    navComponent.refresh();
                }

                this.cd.markForCheck();
            }
        })

    }

    ngOnDestroy(): void {
    }

    setActiveLang(lang: string): void {
        // Set the active lang
        // this._translocoService.setActiveLang(lang);

        this.translate.use(lang);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }


    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;
        navComponent.refresh();

        // Get the Project dashboard item and update its title
        // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        // if (projectDashboardItem) {
        //     this._translocoService.selectTranslate('Project').pipe(take(1))
        //         .subscribe((translation) => {

        //             // Set the title
        //             projectDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }

        // // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        // if (analyticsDashboardItem) {
        //     this._translocoService.selectTranslate('Analytics').pipe(take(1))
        //         .subscribe((translation) => {

        //             // Set the title
        //             analyticsDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }
}
