import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BannerSandbox } from 'app/core/banner/banner.sandbox';
import { BannerModel } from 'app/core/banner/models/banner-item.model';
import { CommonSandbox } from 'app/core/common/common.sandbox';
import { CategoryModel } from 'app/core/common/models/category.model';
import { PageInfoModel } from 'app/core/common/models/page-info.model';
import { BANNER_TYPE } from 'app/core/shared/helpers/banner.types';
import { ShopSandbox } from 'app/core/shop/shop.sandbox';
// import { MessagesService } from 'app/layout/common/~messages/messages.service';
import { Subscription } from 'rxjs';
import { SnackService } from '../snack.service';

@Component({
    templateUrl: './create-banner.component.html',
    styleUrls: ['./create-banner.component.scss']
})
export class CreateBannerDialogComponent implements OnInit, OnDestroy {
    public BANNER_TYPE = BANNER_TYPE;
    private subs: Subscription[] = [];

    public availableCategories: CategoryModel[] = [];

    shopName: FormControl = new FormControl({ value: '', disabled: true }, Validators.required);
    category: FormControl = new FormControl(null);
    bannerType: FormControl = new FormControl(null, Validators.required);
    bannerHeight: FormControl = new FormControl(300, Validators.required);
    bannerContent: FormControl = new FormControl('');
    bannerName: FormControl = new FormControl('', Validators.required);

    pagesList: PageInfoModel[] = [];

    bannerForm: FormGroup;

    constructor(
        private commonSandbox: CommonSandbox,
        private message: SnackService,
        private bannerSandbox: BannerSandbox,
        private dialogRef: MatDialogRef<CreateBannerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BannerModel,
        private cd: ChangeDetectorRef,
        private shopSandbox: ShopSandbox,
        private translate: TranslateService,
        private fb: FormBuilder
    ) {
        this.bannerSandbox.doClearAddBannerState();
        this.bannerSandbox.doClearUpdateBannerItemState();
    }

    ngOnInit(): void {
        this.bannerForm = this.fb.group({
            shopName: this.shopName,
            category: this.category,
            bannerType: this.bannerType,
            bannerHeight: this.bannerHeight,
            bannerContent: this.bannerContent,
            bannerName: this.bannerName,
        });

        this.subs.push(this.shopSandbox.currentShopName$.subscribe(shopName => {
            this.shopName.patchValue(shopName || '');

            if (shopName) {
                this.commonSandbox.doGetShopPages({
                    idShopname: shopName
                });
                this.commonSandbox.doGetCategoryList({
                    idShopname: shopName,
                    // limit: '0',
                    lng: this.translate.currentLang,
                    offset: '0',
                    keyword: ''
                });
            }
        }));

        this.subs.push(this.commonSandbox.shopPagesData$.subscribe(res => {
            if (res) {
                this.pagesList = res;
                this.cd.markForCheck();
            }
        }));
        this.subs.push(this.commonSandbox.getCategoryListData$.subscribe(res => {
            if (res) {
                this.availableCategories = res;
                this.category.patchValue(this.availableCategories[0] || null);
            }
            this.pathEditedValues();
        }));
        this.subs.push(this.bannerSandbox.addBannerData$.subscribe(res => {
            if (res) {
                this.message.log('Banner added succesfully');
                this.dialogRef.close(res);
            }
        }));
        this.subs.push(this.bannerSandbox.updateBannerData$.subscribe(res => {
            if (res) {
                this.message.log('Banner updated succesfully');
                this.dialogRef.close(res);
            }
        }));
        this.subs.push(this.bannerSandbox.updateBannerErrMes$.subscribe(res => {
            if (res) {
                this.message.log(res);
            }
        }));

        this.pathEditedValues();
    }

    private pathEditedValues(): void {
        if (this.data) {
            this.shopName.patchValue(this.data.idshopname);

            if (!!this.availableCategories.length) {
                const cat = this.availableCategories.find(el => +el.id === +this.data.categoryid);
                if (cat) {
                    this.category.patchValue(cat);
                }
            }

            this.bannerType.patchValue(this.data.bannertype);
            this.bannerContent.patchValue(this.data.contentpage);
            this.bannerHeight.patchValue(this.data.height);
            this.bannerName.patchValue(this.data.bannername);
            this.cd.markForCheck();
        }
    }

    create(): void {
        if (this.bannerForm.invalid) {
            this.message.log('Form invalid');
        } else {
            this.bannerForm.disable();

            const params: any = {
                categoryId: '' + (this.category.value && this.category.value.id ? this.category.value.id : ''),
                contentPage: '' + this.bannerContent.value,
                height: '' + this.bannerHeight.value,
                idShopname: this.shopName.value,
                name: this.bannerName.value,
                type: this.bannerType.value,
            };
            if (this.data && this.data.position) {
                params.position = this.data.position;
            } else {
                params.position = 0;
            }

            if (!this.data) {
                this.bannerSandbox.doAddBannerItem(params);
            } else {
                this.bannerSandbox.doUpdateBannerItem('' + this.data.id, params);
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
