import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { VerifyError } from "app/core/shared/helpers/verify-settings";
import { ShopSandbox } from "app/core/shop/shop.sandbox";
import { Subscription } from "rxjs";

@Component({
    selector: 'settings-error-banner',
    templateUrl: './settings-error-baner.component.html'
})
export class SettingsErrorBanner implements OnInit, OnDestroy {
    private subs: Subscription[] = [];
    errors: VerifyError[] = [];

    constructor(
        public shopSandbox: ShopSandbox,
    ) { }

    ngOnInit(): void {
        this.subs.push(this.shopSandbox.shopSettingsErrors$.subscribe(res => {
            if (!!res?.length) {
                this.errors = res;
            }
        }))
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}