export class CurrencyModel {
    conversationrate: number;
    id: number;
    isocode: string;
    namecurrency: any;
    symbolcurrency: string;

    constructor(res: any) {
        this.conversationrate = res.conversationrate || 1;
        this.id = res.id;
        this.isocode = res.isocode || '';
        this.namecurrency = res.namecurrency || {};
        this.symbolcurrency = res.symbolcurrency || '';
    }
}