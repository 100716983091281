<h1 mat-dialog-title>
    <ng-container *ngIf="!data">
        {{'Add translation' | translate}}
    </ng-container>
    <ng-container *ngIf="data">
        {{'Edit translation' | translate}}
    </ng-container>
</h1>
<div mat-dialog-content style="display: flex; flex-direction: column; min-width: 480px;">
    <form [formGroup]="form" style="display: flex; flex-direction: column;">
        <mat-form-field appearance="fill">
            <mat-label>{{'Shop language' | translate}}</mat-label>
            <mat-select [formControl]="lang">
                <mat-option *ngFor="let lan of langList" [value]="lan">
                    {{lan.name}} ({{lan.isocode}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'Text' | translate}}</mat-label>
            <textarea matInput placeholder="Ex. Pizza" [formControl]="text" style="height: 120px;"></textarea>
        </mat-form-field>        
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="false">{{'Close' | translate}}</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid">
        <ng-container *ngIf="!data">
            {{'Add' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            {{'Update' | translate}}
        </ng-container>
    </button>
</div>