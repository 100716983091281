import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { AppState as State } from './state.interface';

import * as fromAuth from './auth/auth.reducer';
import * as fromAccount from './account/account.reducer';
import * as fromBanner from './banner/banner.reducer';
import * as fromCommon from './common/common.reducer';
import * as fromShop from './shop/shop.reducer';
import * as fromRoot from './root/root.reducer';

export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    account: fromAccount.reducer,
    banner: fromBanner.reducer,
    common: fromCommon.reducer,
    shop: fromShop.reducer,
    root: fromRoot.reducer
}

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
    return function (state: State, action: any): State {
        return reducer(state, action);
    };
}
