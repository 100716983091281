export class SliderModel {
    public id: number;
    public idBanner: string;
    public idShopname: string;
    public itemImage: string;
    public itemName: string;
    public itemPath: string;
    public itemPosition: number;
    public itemPositionTitle: string;
    public itemSubtitle: string;
    public itemTitle: string;
    public itemTitleText: string;
    public itemType: string;
    public itemUrl: string;
    public resizeHeightImage: string;
    public resizeWidthImage: string;

    public titleTranslation: any;
    public titletextTranslation: any;

    public color: string;
    public position2: string;

    public redirecturl: string;
    public typeurl: string;

    constructor(res: any) {
        this.id = res.id;
        this.idBanner = res.idBanner;
        this.idShopname = res.idShopname;
        this.itemImage = res.itemImage;
        this.itemName = res.itemName;
        this.itemPath = res.itemPath;
        this.itemPosition = +res.itemPosition;
        this.itemPositionTitle = res.itemPositionTitle;
        this.itemSubtitle = res.itemSubtitle;
        this.itemTitle = res.itemTitle;
        this.itemTitleText = res.itemTitleText;
        this.itemType = res.itemType;
        this.itemUrl = res.itemUrl;
        this.resizeHeightImage = res.resizeHeightImage;
        this.resizeWidthImage = res.resizeWidthImage;

        this.color = res.color;
        this.position2 = res.position2;
        this.redirecturl = res.redirecturl;
        this.typeurl = res.typeurl;

        this.titleTranslation = res.titleTranslation ? res.titleTranslation : {};
        this.titletextTranslation = res.titletextTranslation ? res.titletextTranslation : {};
    }
}

