import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducer.interface';

import { AuthSandbox } from './auth/auth.sandbox';
import { AuthService } from './auth/auth.service';
import { AuthEffects } from './auth/auth.effect';
import { AccountEffect } from './account/account.effect';
import { AccountSandbox } from './account/account.sandbox';
import { AccountService } from './account/account.service';
import { AppSettingService } from './shared/app.settings';
import { ShopEffect } from './shop/shop.effect';
import { ShopSandbox } from './shop/shop.sandbox';
import { ShopService } from './shop/shop.service';
import { RootEffect } from './root/root.effect';
import { RootSandbox } from './root/root.sandbox';
import { RootService } from './root/root.service';
import { CommonEffect } from './common/common.effect';
import { CommonSandbox } from './common/common.sandbox';
import { CommonService } from './common/common.service';
import { BannerEffect } from './banner/banner.effect';
import { BannerSandbox } from './banner/banner.sandbox';
import { BannerService } from './banner/banner.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        AuthModule,
        IconsModule,
        TranslocoCoreModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot([
            AuthEffects,
            AccountEffect,
            ShopEffect,
            RootEffect,
            CommonEffect,
            BannerEffect
        ]),
        TranslateModule
    ],
    providers: [
        AuthSandbox,
        AuthService,
        AccountSandbox,
        AccountService,
        AppSettingService,
        ShopSandbox,
        ShopService,
        RootSandbox,
        RootService,
        CommonSandbox,
        CommonService,
        BannerSandbox,
        BannerService
    ]
})
export class CoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
