import { Action } from '@ngrx/store';
import { type } from '../shared/utility/utilityHelper';

export const ActionTypes = {
    ADD_SHOP: type('ADD_SHOP'),
    ADD_SHOP_SUCCESS: type('ADD_SHOP_SUCCESS'),
    ADD_SHOP_FAIL: type('ADD_SHOP_FAIL'),
    CLEAR_ADD_SHOP_STATE: type('CLEAR_ADD_SHOP_STATE'),

    GET_SHOP_SETTINGS: type('GET_SHOP_SETTINGS'),
    GET_SHOP_SETTINGS_SUCCESS: type('GET_SHOP_SETTINGS_SUCCESS'),
    GET_SHOP_SETTINGS_FAIL: type('GET_SHOP_SETTINGS_FAIL'),
    CLEAR_GET_SHOP_SETTINGS_STATE: type('CLEAR_GET_SHOP_SETTINGS_STATE'),

    UPDATE_MENU_ITEM: type('UPDATE_MENU_ITEM'),
    UPDATE_MENU_ITEM_SUCCESS: type('UPDATE_MENU_ITEM_SUCCESS'),
    UPDATE_MENU_ITEM_FAIL: type('UPDATE_MENU_ITEM_FAIL'),
    CLEAR_UPDATE_MENU_ITEM: type('CLEAR_UPDATE_MENU_ITEM'),

    GET_MENU_ITEM: type('GET_MENU_ITEM'),
    GET_MENU_ITEM_SUCCESS: type('GET_MENU_ITEM_SUCCESS'),
    GET_MENU_ITEM_FAIL: type('GET_MENU_ITEM_FAIL'),

    GET_ORDER_STATUS_LIST: type('GET_ORDER_STATUS_LIST'),
    GET_ORDER_STATUS_LIST_SUCCESS: type('GET_ORDER_STATUS_LIST_SUCCESS'),
    GET_ORDER_STATUS_LIST_FAIL: type('GET_ORDER_STATUS_LIST_FAIL'),
    CLEAR_GET_ORDER_STATUS_LIST_STATE: type('CLEAR_GET_ORDER_STATUS_LIST_STATE'),

    GET_SHOP_LANG_LIST: type('GET_SHOP_LANG_LIST'),
    GET_SHOP_LANG_LIST_SUCCESS: type('GET_SHOP_LANG_LIST_SUCCESS'),
    GET_SHOP_LANG_LIST_FAIL: type('GET_SHOP_LANG_LIST_FAIL'),
    CLEAR_GET_SHOP_LANG_LIST_STATE: type('CLEAR_GET_SHOP_LANG_LIST_STATE'),

    GET_BRANDS_LIST: type('GET_BRANDS_LIST'),
    GET_BRANDS_LIST_SUCCESS: type('GET_BRANDS_LIST_SUCCESS'),
    GET_BRANDS_LIST_FAIL: type('GET_BRANDS_LIST_FAIL'),
    CLEAR_GET_BRANDS_LIST_STATE: type('CLEAR_GET_BRANDS_LIST_STATE'),

    CREATE_PAYPAL_HOOK: type('CREATE_PAYPAL_HOOK'),
    CREATE_PAYPAL_HOOK_SUCCESS: type('CREATE_PAYPAL_HOOK_SUCCESS'),
    CREATE_PAYPAL_HOOK_FAIL: type('CREATE_PAYPAL_HOOK_FAIL'),
    CLEAR_CREATE_PAYPAL_HOOK_STATE: type('CLEAR_CREATE_PAYPAL_HOOK_STATE'),

    CREATE_STRIPE_HOOK: type('CREATE_STRIPE_HOOK'),
    CREATE_STRIPE_HOOK_SUCCESS: type('CREATE_STRIPE_HOOK_SUCCESS'),
    CREATE_STRIPE_HOOK_FAIL: type('CREATE_STRIPE_HOOK_FAIL'),
    CLEAR_CREATE_STRIPE_HOOK_STATE: type('CLEAR_CREATE_STRIPE_HOOK_STATE'),

    GET_SHOP_ERRORS_LIST: type('GET_SHOP_ERRORS_LIST'),
    GET_SHOP_ERRORS_LIST_SUCCESS: type('GET_SHOP_ERRORS_LIST_SUCCESS'),
    GET_SHOP_ERRORS_LIST_FAIL: type('GET_SHOP_ERRORS_LIST_FAIL'),
    CLEAR_GET_SHOP_ERRORS_LIST_STATE: type('CLEAR_GET_SHOP_ERRORS_LIST_STATE'),

    UPDATE_ERROR_STATUS: type('UPDATE_ERROR_STATUS'),
    UPDATE_ERROR_STATUS_SUCCESS: type('UPDATE_ERROR_STATUS_SUCCESS'),
    UPDATE_ERROR_STATUS_FAIL: type('UPDATE_ERROR_STATUS_FAIL'),
    CLEAR_UPDATE_ERROR_STATUS_STATE: type('CLEAR_UPDATE_ERROR_STATUS_STATE'),

    GET_PRODUCTS: type('GET_PRODUCTS'),
    GET_PRODUCTS_FAIL: type('GET_PRODUCTS fail'),
    GET_PRODUCTS_SUCCESS: type('GET_PRODUCTS success'),

    GET_CURRENCY_LIST: type('GET_CURRENCY_LIST'),
    GET_CURRENCY_LIST_FAIL: type('GET_CURRENCY_LIST fail'),
    GET_CURRENCY_LIST_SUCCESS: type('GET_CURRENCY_LIST success'),
};

export class AddShopAction implements Action {
    type = ActionTypes.ADD_SHOP;
    constructor(public payload: any) { }
}
export class AddShopSuccessAction implements Action {
    type = ActionTypes.ADD_SHOP_SUCCESS;
    constructor(public payload: any) { }
}
export class AddShopFailAction implements Action {
    type = ActionTypes.ADD_SHOP_FAIL;
    constructor(public payload: any) { }
}
export class ClearAddShopStateAction implements Action {
    type = ActionTypes.CLEAR_ADD_SHOP_STATE;
    constructor(public payload: any) { }
}

export class GetShopSettingsAction implements Action {
    type = ActionTypes.GET_SHOP_SETTINGS;
    constructor(public payload: any) { }
}
export class GetShopSettingsFailAction implements Action {
    type = ActionTypes.GET_SHOP_SETTINGS_FAIL;
    constructor(public payload: any) { }
}
export class GetShopSettingsSuccessAction implements Action {
    type = ActionTypes.GET_SHOP_SETTINGS_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetShopSettingsStateAction implements Action {
    type = ActionTypes.CLEAR_GET_SHOP_SETTINGS_STATE;
    constructor(public payload: any) { }
}

export class UpdateMenuItemAction implements Action {
    type = ActionTypes.UPDATE_MENU_ITEM;
    constructor(public payload: any) { }
}
export class UpdateMenuItemFailAction implements Action {
    type = ActionTypes.UPDATE_MENU_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class UpdateMenuItemSuccessAction implements Action {
    type = ActionTypes.UPDATE_MENU_ITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUpdateMenuItemAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_MENU_ITEM;
    constructor(public payload: any) { }
}

export class GetMenuItemAction implements Action {
    type = ActionTypes.GET_MENU_ITEM;
    constructor(public payload: any) { }
}
export class GetMenuItemFailAction implements Action {
    type = ActionTypes.GET_MENU_ITEM_FAIL;
    constructor(public payload: any) { }
}
export class GetMenuItemSuccessAction implements Action {
    type = ActionTypes.GET_MENU_ITEM_SUCCESS;
    constructor(public payload: any) { }
}

export class GetOrderStatusListAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_LIST;
    constructor(public payload: any) { }
}
export class GetOrderStatusListSuccessAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class GetOrderStatusListFailAction implements Action {
    type = ActionTypes.GET_ORDER_STATUS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class ClearGetOrderStatusListStateAction implements Action {
    type = ActionTypes.CLEAR_GET_ORDER_STATUS_LIST_STATE;
    constructor(public payload: any) { }
}

export class GetShopLangListAction implements Action {
    type = ActionTypes.GET_SHOP_LANG_LIST;
    constructor(public payload: any) { }
}
export class GetShopLangListFailAction implements Action {
    type = ActionTypes.GET_SHOP_LANG_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetShopLangListSuccessAction implements Action {
    type = ActionTypes.GET_SHOP_LANG_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetShopLangListStateAction implements Action {
    type = ActionTypes.CLEAR_GET_SHOP_LANG_LIST_STATE;
    constructor(public payload: any) { }
}

export class GetBrandsListAction implements Action {
    type = ActionTypes.GET_BRANDS_LIST;
    constructor(public payload: any) { }
}
export class GetBrandsListFailAction implements Action {
    type = ActionTypes.GET_BRANDS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetBrandsListSuccessAction implements Action {
    type = ActionTypes.GET_BRANDS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetBrandsListStateAction implements Action {
    type = ActionTypes.CLEAR_GET_BRANDS_LIST_STATE;
    constructor(public payload: any) { }
}

export class CreatePaypalHookAction implements Action {
    type = ActionTypes.CREATE_PAYPAL_HOOK;
    constructor(public payload: any) { }
}
export class CreatePaypalHookSuccessAction implements Action {
    type = ActionTypes.CREATE_PAYPAL_HOOK_SUCCESS;
    constructor(public payload: any) { }
}
export class CreatePaypalHookFailAction implements Action {
    type = ActionTypes.CREATE_PAYPAL_HOOK_FAIL;
    constructor(public payload: any) { }
}
export class ClearCreatePaypalHookStateAction implements Action {
    type = ActionTypes.CLEAR_CREATE_PAYPAL_HOOK_STATE;
    constructor(public payload: any) { }
}

export class CreateStripeHookAction implements Action {
    type = ActionTypes.CREATE_STRIPE_HOOK;
    constructor(public payload: any) { }
}
export class CreateStripeHookSuccessAction implements Action {
    type = ActionTypes.CREATE_STRIPE_HOOK_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateStripeHookFailAction implements Action {
    type = ActionTypes.CREATE_STRIPE_HOOK_FAIL;
    constructor(public payload: any) { }
}
export class ClearCreateStripeHookStateAction implements Action {
    type = ActionTypes.CLEAR_CREATE_STRIPE_HOOK_STATE;
    constructor(public payload: any) { }
}

export class GetShopErrorListAction implements Action {
    type = ActionTypes.GET_SHOP_ERRORS_LIST;
    constructor(public payload: any) { }
}
export class GetShopErrorListFailAction implements Action {
    type = ActionTypes.GET_SHOP_ERRORS_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetShopErrorListSuccessAction implements Action {
    type = ActionTypes.GET_SHOP_ERRORS_LIST_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearGetShopErrorListStateAction implements Action {
    type = ActionTypes.CLEAR_GET_SHOP_ERRORS_LIST_STATE;
    constructor(public payload: any) { }
}

export class UpdateErrorStatusAction implements Action {
    type = ActionTypes.UPDATE_ERROR_STATUS;
    constructor(public payload: any) { }
}
export class UpdateErrorStatusFailAction implements Action {
    type = ActionTypes.UPDATE_ERROR_STATUS_FAIL;
    constructor(public payload: any) { }
}
export class UpdateErrorStatusSuccessAction implements Action {
    type = ActionTypes.UPDATE_ERROR_STATUS_SUCCESS;
    constructor(public payload: any) { }
}
export class ClearUpdateErrorStatusStateAction implements Action {
    type = ActionTypes.CLEAR_UPDATE_ERROR_STATUS_STATE;
    constructor(public payload: any) { }
}
// 
export class GetProductsAction implements Action {
    type = ActionTypes.GET_PRODUCTS;
    constructor(public payload: any) { }
}
export class GetProductsFailAction implements Action {
    type = ActionTypes.GET_PRODUCTS_FAIL;
    constructor(public payload: any) { }
}
export class GetProductsSuccessAction implements Action {
    type = ActionTypes.GET_PRODUCTS_SUCCESS;
    constructor(public payload: any) { }
}

export class GetCurrencyListAction implements Action {
    type = ActionTypes.GET_CURRENCY_LIST;
    constructor(public payload: any) { }
}
export class GetCurrencyListFailAction implements Action {
    type = ActionTypes.GET_CURRENCY_LIST_FAIL;
    constructor(public payload: any) { }
}
export class GetCurrencyListSuccessAction implements Action {
    type = ActionTypes.GET_CURRENCY_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export type Actions =
    | AddShopAction
    | AddShopFailAction
    | AddShopSuccessAction
    | ClearAddShopStateAction
    | GetShopSettingsAction
    | GetShopSettingsFailAction
    | GetShopSettingsSuccessAction
    | ClearGetShopSettingsStateAction
    | UpdateMenuItemAction
    | UpdateMenuItemFailAction
    | UpdateMenuItemSuccessAction
    | ClearUpdateMenuItemAction
    | GetMenuItemAction
    | GetMenuItemFailAction
    | GetMenuItemSuccessAction
    | GetOrderStatusListAction
    | GetOrderStatusListFailAction
    | GetOrderStatusListSuccessAction
    | ClearGetOrderStatusListStateAction
    | GetShopLangListAction
    | GetShopLangListFailAction
    | GetShopLangListSuccessAction
    | ClearGetShopLangListStateAction
    | GetBrandsListAction
    | GetBrandsListFailAction
    | GetBrandsListSuccessAction
    | ClearGetBrandsListStateAction
    | CreatePaypalHookAction
    | CreatePaypalHookFailAction
    | CreatePaypalHookSuccessAction
    | ClearCreatePaypalHookStateAction
    | CreateStripeHookAction
    | CreateStripeHookFailAction
    | CreateStripeHookSuccessAction
    | ClearCreateStripeHookStateAction
    | GetShopErrorListAction
    | GetShopErrorListFailAction
    | GetShopErrorListSuccessAction
    | ClearGetShopErrorListStateAction
    | UpdateErrorStatusAction
    | UpdateErrorStatusFailAction
    | UpdateErrorStatusSuccessAction
    | ClearUpdateErrorStatusStateAction
    | GetCurrencyListAction
    | GetCurrencyListFailAction
    | GetCurrencyListSuccessAction

