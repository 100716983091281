/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const USER_NAVIGATION: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'General',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.initialization',
                title: 'Initialization',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/initialization'
            },
            {
                id: 'dashboards.shop-languages',
                title: 'Shop languages',
                type: 'basic',
                icon: 'heroicons_outline:translate',
                link: '/shop-languages'
            },
            {
                id: 'dashboards.currency-list',
                title: 'Shop currency',
                type: 'basic',
                icon: 'heroicons_outline:currency-euro',
                link: '/currency-list'
            },
            {
                id: 'dashboards.errors',
                title: 'Errors',
                type: 'basic',
                icon: 'warning',
                link: '/errors'
            },
            {
                id: 'dashboards.errors',
                title: 'Settings',
                type: 'basic',
                icon: 'settings',
                link: '/settings'
            },
        ]
    },
    {
        id: 'dashboards',
        title: 'Modules',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.banner',
                title: 'Banner',
                type: 'basic',
                icon: 'apps',
                link: '/banner'
            },
            {
                id: 'dashboards.menu',
                title: 'Menu',
                type: 'basic',
                icon: 'heroicons_outline:menu',
                link: '/menu'
            },
            {
                id: 'dashboards.notification',
                title: 'Notifications',
                type: 'basic',
                icon: 'add_alert',
                link: '/notification'
            },
            // {
            //     id: 'dashboards.templates',
            //     title: 'Templates',
            //     type: 'basic',
            //     icon: 'insert_drive_file',
            //     link: '/templates'
            // }
        ]
    },
];

export const USER_NO_SHOP_NAVIGATION: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'General',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.initialization',
                title: 'Initialization',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/initialization'
            }
        ]
    }
];

export const ADMIN_ROOT_NAVIGATION: FuseNavigationItem[] = [
    {
        id: 'root',
        title: 'Root',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'root.user-list',
                title: 'Users list',
                type: 'basic',
                icon: 'supervised_user_circle',
                link: '/user-list'
            },
            {
                id: 'root.cluster-list',
                title: 'Cluster list',
                type: 'basic',
                icon: 'desktop_windows',
                link: '/cluster-list'
            }
        ]
    }
];