<h1 mat-dialog-title>
    <ng-container *ngIf="!data">
        {{'Add Menu item' | translate}}
    </ng-container>
    <ng-container *ngIf="data">
        {{'Update menu item' | translate}}
    </ng-container>
</h1>
<div mat-dialog-content style="display: flex; flex-direction: column; min-width: 480px;">
    <div class="flex">
        <mat-form-field class="flex-auto">
            <mat-label>{{'Name' | translate}}</mat-label>
            <input matInput placeholder="Ex. Pizza" [formControl]="name">
            <mat-icon class="icon-size-5" matPrefix>notes</mat-icon>
        </mat-form-field>
    </div>

    <div class="flex justify-between items-center" style="min-height: 40px;">
        <mat-checkbox [(ngModel)]="useTranslations">{{'Use translations' | translate}}
        </mat-checkbox>
        <ng-container *ngIf="useTranslations">
            <button mat-raised-button color="primary" (click)="addTranslation()">
                <mat-icon class="icon-size-5 mr-2">add</mat-icon>
                {{'Add new' | translate}}
            </button>
        </ng-container>
    </div>

    <div>
        <ng-container *ngIf="useTranslations">
            <ul style="padding-left: 20px;">
                <li *ngFor="let key of objectKeys(translatesCollection)">
                    <div class="lan-list-item">
                        <span>
                            <b class="uppercase">{{key}}:</b>
                            {{translatesCollection[key]}}
                        </span>
                        <div class="row">
                            <button mat-raised-button color="primary" (click)="removeKey(key)">
                                <mat-icon class="icon-size-5 mr-2">edit</mat-icon>
                                {{'Remove' | translate}}
                            </button>
                            &nbsp;
                            <button mat-raised-button color="primary" (click)="editItem(key)">
                                <mat-icon class="icon-size-5 mr-2">delete_sweep</mat-icon>
                                {{'Edit' | translate}}
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- <div style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
                <button mat-raised-button color="primary" (click)="addTranslation()">{{'Add new' | translate}}</button>
            </div> -->

            <!-- <ng-container *ngFor="let lan of langList">
                <mat-form-field class="example-full-width">
                    <mat-label>{{lan}}:</mat-label>
                    <input matInput placeholder="{{lan}} translation" [(ngModel)]="translatesCollection[lan]">
                </mat-form-field>
            </ng-container> -->
        </ng-container>
    </div>

    <div class="flex">
        <mat-form-field appearance="fill">
            <mat-label>{{'Type' | translate}}:</mat-label>
            <mat-select [formControl]="itemType">
                <mat-option [value]="'page'">
                    {{'Page' | translate}}
                </mat-option>
                <mat-option [value]="'product'">
                    {{'Product' | translate}}
                </mat-option>
                <mat-option [value]="'category'">
                    {{'Category' | translate}}
                </mat-option>
                <mat-option [value]="'brand'">
                    {{'Brand' | translate}}
                </mat-option>
                <mat-option [value]="'url'">
                    {{'Url' | translate}}
                </mat-option>
                <mat-option [value]="'offers'">
                    {{'Offers' | translate}}
                </mat-option>
                <mat-option [value]="'brand-list'">
                    {{'Brand list' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field class="example-full-width" *ngIf="itemType.value === 'url'">
        <mat-label>{{'Value' | translate}}:</mat-label>
        <input matInput placeholder="url" [formControl]="url">
        <mat-icon class="icon-size-5" matPrefix>cloud</mat-icon>
        <mat-hint>{{'Url and shop link must be different' | translate}}!</mat-hint>
        <mat-error *ngIf="url.hasError('invalidLink')">
            {{'Invalid link' | translate}}
        </mat-error>
    </mat-form-field>

    <div class="flex">
        <mat-form-field class="full-width" *ngIf="itemType.value === 'page'">
            <mat-label>{{'Content page' | translate}}</mat-label>
            <mat-select [formControl]="page">
                <mat-option *ngFor="let page of pagesList" [value]="page">
                    ID: {{page.id}} - {{page.title | multiLang}}
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix>note</mat-icon>
        </mat-form-field>
    </div>

    <div class="flex">
        <mat-form-field *ngIf="itemType.value === 'brand'">
            <mat-label>{{'Brand' | translate}}</mat-label>
            <mat-select [formControl]="brand">
                <mat-option *ngFor="let brand_ of brands" [value]="brand_">
                    ID: {{brand_.id_manufacturer}} - {{brand_.meta_title | multiLang}} ({{brand_.name}})
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix>stars</mat-icon>
        </mat-form-field>
    </div>

    <div *ngIf="itemType.value === 'category'" class="flex justify-between items-center">
        <ng-container *ngIf="selectedCategory; else noItem">
            #{{selectedCategory.id}} - {{selectedCategory.name | multiLang}}
        </ng-container>

        <button mat-raised-button color="primary" (click)="selectCategory()">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Select category' | translate}}
        </button>
    </div>

    <div *ngIf="itemType.value === 'product'" class="flex justify-between items-center">
        <ng-container *ngIf="selectedProduct; else noItem">
            #{{selectedProduct.id}} - {{selectedProduct.title | multiLang}}
        </ng-container>
        <button mat-raised-button color="primary" (click)="selectProduct()">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Select product' | translate}}
        </button>
    </div>

    <div *ngIf="IMAGES_FOR_MENU">
        <ng-container *ngIf="image && image.path && image.nameFile">
            <img [src]="apiUrl + image.path + image.nameFile" class="img-prew" width="220" style="width: 220px;">
            <br>
        </ng-container>

        <div class="pb-5" *ngIf="image && image.path">
            <button mat-raised-button color="basic" (click)="removeImage()">
                {{'Delete Image' | translate}}
            </button>
        </div>
        
        <div class="pb-5">
            <button mat-raised-button color="primary" (click)="uploadImage()">
                <mat-icon class="icon-size-5 mr-2">cloud_download</mat-icon>
                {{'Upload Image' | translate}}
            </button>
        </div>
        
    </div>
    <!-- {{ (url.hasError('invalidLink') && itemType.value === 'url')}} -->
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="false">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Close' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="Add()"
        [disabled]="name.invalid || (url.hasError('invalidLink') && itemType.value === 'url')">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Add' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Update' | translate}}
        </ng-container>
    </button>
</div>

<ng-template #noItem>
    <span></span>
</ng-template>