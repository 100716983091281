<h1 mat-dialog-title>{{'Shop settings' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{'Shop link' | translate}}</mat-label>
            <input matInput placeholder="{{'Shop link' | translate}}" type="text" required [formControl]="url">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
            <mat-error *ngIf="url.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Api link' | translate}}</mat-label>
            <input matInput placeholder="{{'Api link' | translate}}" type="text" required [formControl]="urlApi">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
            <mat-error *ngIf="urlApi.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>WebService</mat-label>
            <input matInput placeholder="{{'WebService'" type="text" required [formControl]="ws">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
            <mat-error *ngIf="ws.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Apple ID</mat-label>
            <input matInput placeholder="{{'Apple ID'}}" type="text"  [formControl]="appleId">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Apple Bungle Id</mat-label>
            <input matInput placeholder="{{'Apple Bungle Id'}}" type="text"  [formControl]="appleBundleId">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Android ID</mat-label>
            <input matInput placeholder="{{'Android ID'}}" type="text"  [formControl]="androidId">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
            <mat-error *ngIf="androidId.hasError('required')">
                {{'Required' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Onesignal app Id</mat-label>
            <input matInput placeholder="{{'Onesignal app Id'}}" type="text"  [formControl]="oneSignalId">
            <mat-icon class="icon-size-5" matPrefix>settings</mat-icon>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="confirm()" [disabled]="form.invalid">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Create' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Edit' | translate}}
        </ng-container>
    </button>
    <button mat-raised-button color="primary" (click)="cancel()">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Cancel' | translate}}
    </button>
</div>