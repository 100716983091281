import { SliderModel } from './slider.model';

export class BannerModel {
    public bannername: string;
    public bannertype: string;
    public categoryid: string;
    public contentpage: string;
    public height: string;
    public id: number;
    public idshopname: string;
    public items: SliderModel[];
    public position: number;

    constructor(res: any) {
        this.bannername = res.bannername || '';
        this.bannertype = res.bannertype || '';
        this.categoryid = res.categoryid || '';
        this.contentpage = res.contentpage || '';
        this.height = res.height;
        this.id = res.id;
        this.idshopname = res.idshopname || '';
        this.items = res.items;
        this.position = res.position || 0;
    }
}

