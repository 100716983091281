import { Map, Record } from 'immutable';

export interface RootState extends Map<string, any> {
    getUsersListLoading: boolean;
    getUsersListLoaded: boolean;
    getUsersListFail: boolean;
    getUsersListData: any;

    createUserLoading: boolean;
    createUserLoaded: boolean;
    createUserFail: boolean;
    createUserData: any;

    getClustersListLoading: boolean;
    getClustersListLoaded: boolean;
    getClustersListFail: boolean;
    getClustersListData: any;
    createUserErrMess: string;

    getShopClusterLoading: boolean;
    getShopClusterLoaded: boolean;
    getShopClusterFail: boolean;
    getShopClusterData: any;

    clusterSaveLoading: boolean;
    clusterSaveLoaded: boolean;
    clusterSaveFail: boolean;
    clusterSaveData: any;
    clusterSaveMsg: string;

    updateUserLoading: boolean;
    updateUserLoaded: boolean;
    updateUserFail: boolean;
    updateUserData: any;

    wipeDataLoading: boolean;
    wipeDataLoaded: boolean;
    wipeDataFail: boolean;
    wipeDataData: any;
}

export const RootRecord = Record({
    getUsersListLoading: false,
    getUsersListLoaded: false,
    getUsersListFail: false,
    getUsersListData: null,

    createUserLoading: false,
    createUserLoaded: false,
    createUserFail: false,
    createUserData: null,
    createUserErrMess: '',

    getClustersListLoading: false,
    getClustersListLoaded: false,
    getClustersListFail: false,
    getClustersListData: null,

    getShopClusterLoading: false,
    getShopClusterLoaded: false,
    getShopClusterFail: false,
    getShopClusterData: null,

    clusterSaveLoading: false,
    clusterSaveLoaded: false,
    clusterSaveFail: false,
    clusterSaveData: null,
    clusterSaveMsg: null,

    updateUserLoading: false,
    updateUserLoaded: false,
    updateUserFail: false,
    updateUserData: null,

    wipeDataLoading: false,
    wipeDataLoaded: false,
    wipeDataFail: false,
    wipeDataData: null
});

