export class LoginUserModel {
    public email: string;
    public role: string;
    public token: string;

    constructor(res: any) {
        this.email = res.email ? res.email : '';
        this.role = res.role ? res.role : 'user';
        this.token = res.token ? res.token : null;
    }
}

