export class LanguageModel {
    public id: string;
    public isocode: string;
    public name: string;

    constructor(res: any) {
        this.id = res.id ? res.id : '';
        this.isocode = res.isocode ? res.isocode : '';
        this.name = res.name ? res.name : '';
    }
}

