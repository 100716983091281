export class BannerCreateModel {
    public CreatedAt: string;
    public DeletedAt: string;
    public id: number;
    public itemsBanner: any;
    public UpdatedAt: string;
    public categoryId: string;
    public contentPage: string;
    public height: string;
    public idShopname: string;
    public name: string;
    public position: number;
    public type: string;

    constructor(res: any) {
        this.CreatedAt = res.CreatedAt || '';
        this.DeletedAt = res.DeletedAt || '';
        this.id = res.ID;
        this.itemsBanner = res.ItemsBanner || {};
        this.UpdatedAt = res.UpdatedAt || '';
        this.categoryId = res.categoryId || '';
        this.contentPage = res.contentPage || '';
        this.height = res.height || '';
        this.idShopname = res.idShopname || '';
        this.name = res.name || '';
        this.position = res.position;
        this.type = res.type;
    }
}
