export class ClusterModel {
    public active: boolean;
    public cluster_id: string;
    public cluster_name: string;
    public date_create: string;

    constructor(res: any) {
        this.active = res.active;
        this.cluster_id = res.cluster_id;
        this.cluster_name = res.cluster_name || '';
        this.date_create = res.date_create;
    }
}

