// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//     production: false,
//     API_URL: 'https://prestapp.galvintec-lab.com/',
//     API_VERSION: 'v1',
//     ROOT_API: 'https://prestapp.galvintec-lab.com/api/dashboard/',
//     IS_CAN_VIDEO_UPLOAD: true,
//     IMAGES_FOR_MENU: false,
// };

export const environment = {
    production: false,
    API_URL: 'http://65.109.230.113:2122/',
    API_VERSION: 'v1',
    ROOT_API: 'http://65.109.230.113:2122/api/dashboard/',
    IS_CAN_VIDEO_UPLOAD: true,
    IMAGES_FOR_MENU: false,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
