<h1 mat-dialog-title>
    <ng-container *ngIf="!data">
        {{'Add translation' | translate}}
    </ng-container>
    <ng-container *ngIf="data">
        {{'Edit translation' | translate}}
    </ng-container>
</h1>
<div mat-dialog-content style="display: flex; flex-direction: column; min-width: 480px;">
    <form [formGroup]="form">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'Text' | translate}}</mat-label>
            <textarea matInput placeholder="Ex. Pizza" [formControl]="text" style="height: 120px;"></textarea>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="fill">
            <mat-label>{{'Shop language' | translate}}</mat-label>
            <mat-select [formControl]="lang">
                <mat-option *ngFor="let lan of langList" [value]="lan">
                    {{lan.name}} ({{lan.isocode}})
                </mat-option>
            </mat-select>
            <mat-hint>
                {{'Required tags' | translate}}:
                <ng-container *ngFor="let w of keywords">
                    <span [class.err]="checkTag(w)">
                        {{w}}
                    </span>
                </ng-container>
            </mat-hint>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="false">
        <mat-icon class="icon-size-5 mr-2">close</mat-icon>
        {{'Close' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid || tagsInvalid">
        <ng-container *ngIf="!data">
            <mat-icon class="icon-size-5 mr-2">add</mat-icon>
            {{'Add' | translate}}
        </ng-container>
        <ng-container *ngIf="data">
            <mat-icon class="icon-size-5 mr-2">sync</mat-icon>
            {{'Update' | translate}}
        </ng-container>
    </button>
</div>