export class ImageFileModel {
    public ext: string;
    public heightImage: string;
    public nameFile: string;
    public path: string;
    public widthImage: string;

    constructor(res: any = null) {
        this.ext = res?.ext || '';
        this.heightImage = res?.heightImage || 0;
        this.nameFile = res?.nameFile || 0;
        this.path = res?.path || '';
        this.widthImage = res?.widthImage || '';
    }
}

