import { RootState, RootRecord } from './root.state';
import * as actions from './root.actions';
import { ClusterModel } from './models/cluster.model';
import { UserModel } from '../account/models/registerUser.model';

export const initialState: RootState = (new RootRecord() as unknown) as RootState;

export function reducer(state = initialState, { type, payload }: any): RootState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.GET_USERS_LIST: {
            return Object.assign({}, state, {
                getUsersListLoading: true,
                getUsersListLoaded: false,
                getUsersListFail: false,
                getUsersListData: null,
            });
        }
        case actions.ActionTypes.GET_USERS_LIST_SUCCESS: {
            let users = [];
            if (payload && payload.data && !!payload.data.length) {
                users = payload.data.map((el: any) => new UserModel(el));
            }
            // console.log(payload);
            return Object.assign({}, state, {
                getUsersListLoading: false,
                getUsersListLoaded: true,
                getUsersListFail: false,
                getUsersListData: users,
            });
        }
        case actions.ActionTypes.GET_USERS_LIST_FAIL: {
            return Object.assign({}, state, {
                getUsersListLoading: false,
                getUsersListLoaded: false,
                getUsersListFail: true,
                getUsersListData: null,
            });
        }

        case actions.ActionTypes.CREATE_USER: {
            return Object.assign({}, state, {
                createUserLoading: true,
                createUserLoaded: false,
                createUserFail: false,
                createUserData: null,
                createUserErrMess: null,
            });
        }
        case actions.ActionTypes.CREATE_USER_SUCCESS: {
            // console.log(payload);
            return Object.assign({}, state, {
                createUserLoading: false,
                createUserLoaded: true,
                createUserFail: false,
                createUserData: payload,
                createUserErrMess: null,
            });
        }
        case actions.ActionTypes.CREATE_USER_FAIL: {
            return Object.assign({}, state, {
                createUserLoading: false,
                createUserLoaded: false,
                createUserFail: true,
                createUserData: null,
                createUserErrMess: payload && payload.message ? payload.message : null,
            });
        }
        case actions.ActionTypes.CLEAR_CREATE_USER_STATE: {
            return Object.assign({}, state, {
                createUserLoading: false,
                createUserLoaded: false,
                createUserFail: false,
                createUserData: null,
                createUserErrMess: null,
            });
        }

        case actions.ActionTypes.GET_CLUSTERS_LIST: {
            return Object.assign({}, state, {
                getClustersListLoading: true,
                getClustersListLoaded: false,
                getClustersListFail: false,
                getClustersListData: null,
            });
        }
        case actions.ActionTypes.GET_CLUSTERS_LIST_SUCCESS: {
            let clusterList = [];
            if (payload && payload.data && !!payload.data.length) {
                clusterList = payload.data.map((el: any) => new ClusterModel(el));
            }

            return Object.assign({}, state, {
                getClustersListLoading: false,
                getClustersListLoaded: true,
                getClustersListFail: false,
                getClustersListData: clusterList,
            });
        }
        case actions.ActionTypes.GET_CLUSTERS_LIST_FAIL: {
            return Object.assign({}, state, {
                getClustersListLoading: false,
                getClustersListLoaded: false,
                getClustersListFail: true,
                getClustersListData: null,
            });
        }

        case actions.ActionTypes.GET_SHOP_CLUSTER: {
            return Object.assign({}, state, {
                getShopClusterLoading: true,
                getShopClusterLoaded: false,
                getShopClusterFail: false,
                getShopClusterData: null
            })
        }
        case actions.ActionTypes.GET_SHOP_CLUSTER_SUCCESS: {
            return Object.assign({}, state, {
                getShopClusterLoading: false,
                getShopClusterLoaded: true,
                getShopClusterFail: false,
                getShopClusterData: payload
            })
        }
        case actions.ActionTypes.GET_SHOP_CLUSTER_FAIL: {
            return Object.assign({}, state, {
                getShopClusterLoading: false,
                getShopClusterLoaded: false,
                getShopClusterFail: true,
                getShopClusterData: null
            })
        }

        case actions.ActionTypes.CLUSTER_SAVE: {
            return Object.assign({}, state, {
                clusterSaveLoading: true,
                clusterSaveLoaded: false,
                clusterSaveFail: false,
                clusterSaveData: null,
                clusterSaveMsg: null,
            });
        }
        case actions.ActionTypes.CLUSTER_SAVE_SUCCESS: {
            return Object.assign({}, state, {
                clusterSaveLoading: false,
                clusterSaveLoaded: true,
                clusterSaveFail: false,
                clusterSaveData: payload,
                clusterSaveMsg: payload.msg ? payload.msg : null,
            });
        }
        case actions.ActionTypes.CLUSTER_SAVE_FAIL: {
            return Object.assign({}, state, {
                clusterSaveLoading: false,
                clusterSaveLoaded: false,
                clusterSaveFail: true,
                clusterSaveData: null,
                clusterSaveMsg: payload.msg ? payload.msg : null,
            });
        }
        case actions.ActionTypes.CLEAR_CLUSTER_SAVE_STATE: {
            return Object.assign({}, state, {
                clusterSaveLoading: false,
                clusterSaveLoaded: false,
                clusterSaveFail: false,
                clusterSaveData: null,
                clusterSaveMsg: null,
            });
        }

        case actions.ActionTypes.UPDATE_USER: {
            return Object.assign({}, state, {
                updateUserLoading: true,
                updateUserLoaded: false,
                updateUserFail: false,
                updateUserData: null,
            });
        }
        case actions.ActionTypes.UPDATE_USER_SUCCESS: {
            return Object.assign({}, state, {
                updateUserLoading: false,
                updateUserLoaded: true,
                updateUserFail: false,
                updateUserData: payload,
            });
        }
        case actions.ActionTypes.UPDATE_USER_FAIL: {
            return Object.assign({}, state, {
                updateUserLoading: false,
                updateUserLoaded: false,
                updateUserFail: true,
                updateUserData: null,
            });
        }
        case actions.ActionTypes.CLEAR_UPDATE_USER_STATE: {
            return Object.assign({}, state, {
                updateUserLoading: false,
                updateUserLoaded: false,
                updateUserFail: false,
                updateUserData: null,
            });
        }

        case actions.ActionTypes.WIPE_DATA: {
            return Object.assign({}, state, {
                wipeDataLoading: true,
                wipeDataLoaded: false,
                wipeDataFail: false,
                wipeDataData: null
            });
        }
        case actions.ActionTypes.WIPE_DATA_SUCCESS: {
            return Object.assign({}, state, {
                wipeDataLoading: false,
                wipeDataLoaded: true,
                wipeDataFail: false,
                wipeDataData: payload
            });
        }
        case actions.ActionTypes.WIPE_DATA_FAIL: {
            return Object.assign({}, state, {
                wipeDataLoading: false,
                wipeDataLoaded: false,
                wipeDataFail: true,
                wipeDataData: null
            });
        }
        case actions.ActionTypes.CLEAR_WIPE_DATA_STATE: {
            return Object.assign({}, state, {
                wipeDataLoading: false,
                wipeDataLoaded: false,
                wipeDataFail: false,
                wipeDataData: null
            });
        }

        default: {
            return { ...state };
        }
    }
}

export const getUsersListLoading = (state: RootState) => state.getUsersListLoading;
export const getUsersListLoaded = (state: RootState) => state.getUsersListLoaded;
export const getUsersListFail = (state: RootState) => state.getUsersListFail;
export const getUsersListData = (state: RootState) => state.getUsersListData;

export const createUserLoading = (state: RootState) => state.createUserLoading;
export const createUserLoaded = (state: RootState) => state.createUserLoaded;
export const createUserFail = (state: RootState) => state.createUserFail;
export const createUserData = (state: RootState) => state.createUserData;
export const createUserErrMess = (state: RootState) => state.createUserErrMess;

export const getClustersListLoading = (state: RootState) => state.getClustersListLoading;
export const getClustersListLoaded = (state: RootState) => state.getClustersListLoaded;
export const getClustersListFail = (state: RootState) => state.getClustersListFail;
export const getClustersListData = (state: RootState) => state.getClustersListData;

export const getShopClusterLoading = (state: RootState) => state.getShopClusterLoading;
export const getShopClusterLoaded = (state: RootState) => state.getShopClusterLoaded;
export const getShopClusterFail = (state: RootState) => state.getShopClusterFail;
export const getShopClusterData = (state: RootState) => state.getShopClusterData;

export const clusterSaveLoading = (state: RootState) => state.clusterSaveLoading;
export const clusterSaveLoaded = (state: RootState) => state.clusterSaveLoaded;
export const clusterSaveFail = (state: RootState) => state.clusterSaveFail;
export const clusterSaveData = (state: RootState) => state.clusterSaveData;
export const clusterSaveMsg = (state: RootState) => state.clusterSaveMsg;

export const updateUserLoading = (state: RootState) => state.updateUserLoading;
export const updateUserLoaded = (state: RootState) => state.updateUserLoaded;
export const updateUserFail = (state: RootState) => state.updateUserFail;
export const updateUserData = (state: RootState) => state.updateUserData;

export const wipeDataLoading = (state: RootState) => state.wipeDataLoading;
export const wipeDataLoaded = (state: RootState) => state.wipeDataLoaded;
export const wipeDataFail = (state: RootState) => state.wipeDataFail;
export const wipeDataData = (state: RootState) => state.wipeDataData;



