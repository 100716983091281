import { Map, Record } from 'immutable';
import { UserModel } from './models/registerUser.model';

export interface AccountState extends Map<string, any> {
    registerUserLoading: boolean;
    registerUserLoaded: boolean;
    registerUserFail: boolean;
    registerUserData: any;
    registerUserErrors: any;

    loginUserLoading: boolean;
    loginUserLoaded: boolean;
    loginUserFail: boolean;
    loginUserData: any;
    loginUserErrors: any;

    lastLoginedUserInfo: any;
    currentUserToken: string;
    currentUserRole: string;

    sendPasswordRecoveryKeyLoading: boolean;
    sendPasswordRecoveryKeyLoaded: boolean;
    sendPasswordRecoveryKeyFail: boolean;
    sendPasswordRecoveryKeyData: any;
    sendPasswordRecoveryKeyErrMessage: any;

    changePasswordLoading: boolean;
    changePasswordLoaded: boolean;
    changePasswordFail: boolean;
    changePasswordData: any;
    changePasswordErrMessage: string;

    doLogOutEmitted: boolean;

    getCurrentUserLoading: boolean;
    getCurrentUserLoaded: boolean;
    getCurrentUserFail: boolean;
    getCurrentUserData: UserModel;

    // currentShopName: string;
}

export const AccountRecord = Record({
    registerUserLoading: false,
    registerUserLoaded: false,
    registerUserFail: false,
    registerUserData: null,
    registerUserErrors: null,

    loginUserLoading: false,
    loginUserLoaded: false,
    loginUserFail: false,
    loginUserData: null,
    loginUserErrors: null,

    lastLoginedUserInfo: null,
    currentUserToken: '',
    currentUserRole: '',

    sendPasswordRecoveryKeyLoading: false,
    sendPasswordRecoveryKeyLoaded: false,
    sendPasswordRecoveryKeyFail: false,
    sendPasswordRecoveryKeyData: null,
    sendPasswordRecoveryKeyErrMessage: null,

    changePasswordLoading: false,
    changePasswordLoaded: false,
    changePasswordFail: false,
    changePasswordData: null,
    changePasswordErrMessage: null,

    doLogOutEmitted: false,

    getCurrentUserLoading: false,
    getCurrentUserLoaded: false,
    getCurrentUserFail: false,
    getCurrentUserData: null,

    // currentShopName: null,
});

