export class OrderStatusSettingsModel {
    public contents: { [key: string]: string } = {};
    public headings: { [key: string]: string } = {};

    constructor(res: any) {
        this.contents = res.contents ? res.contents : {};
        this.headings = res.headings ? res.headings : {};
    }
}

